import { acceptHMRUpdate, defineStore } from 'pinia'
import { Asset, type FetchKpiAggregationPayload, KPI, PortfolioAsset } from './types'
import { ComponentInProjectWithContext, KPIAggregationResult, ProjectWithContext, User } from '@aedifion.io/aedifion-api'
import { computed, ComputedRef, ref } from 'vue'
import { getPortfolioFloorArea, getUnit } from './helpers'
import { validateNonEmptyArray, validateNotNullish } from '@/utils/helpers/validate'
import { getComponentAttribute } from '@/utils/helpers/componentAttribute'
import i18n from '@/i18n'
import moment from 'moment'
import { reportError } from '@/utils/helpers/errors'
import { showErrorNotification } from '@/utils/helpers/notifications'
import texts from '@theme/texts'
import { useAnalyticsApiStore } from '@aedifion.io/pinia-aedifion-api-stores'
import { useUserStore } from '@/stores/user'
import vuexStore from '@/vuex'

export const perSquareUnitKPIs = [
  'energy_cost',
  'co2_emissions',
  'energy_consumption',
  'technical_availability',
  'productivity',
] as const

export const absoluteKPIs = [
  'energy_cost_absolute',
  'co2_emissions_absolute',
  'energy_consumption_absolute',
  'technical_availability',
  'productivity',
] as const

export const useReportingStore = defineStore('reporting', () => {
  const analyticsApiStore = useAnalyticsApiStore()
  const userStore = useUserStore()

  const dateRange = ref<[start: Date, end: Date]>
  ([
    moment().subtract(3, 'month').toDate(),
    moment().subtract(1, 'month').toDate(),
  ])

  const isPerSquareUnit = ref(false)
  const aggregationType = ref<'sum'|'avg'>('sum')
  const kpiAggregationResultAbsolute = ref<KPIAggregationResult[]>([])
  const kpiAggregationResultPerSquareUnit = ref<KPIAggregationResult[]>([])
  const isLoadingKpiAggregation = ref(false)
  const assets: ComputedRef<(kpiType: 'absolute' | 'perSquareUnit') => Asset[] | null> = computed(() => (kpiType: 'absolute' | 'perSquareUnit') => {
    let stateKPI: KPIAggregationResult[]

    if (kpiType === 'absolute') {
      stateKPI = kpiAggregationResultAbsolute.value
      if (kpiAggregationResultAbsolute.value.length === 0) {
        return null
      }
    } else {
      stateKPI = kpiAggregationResultPerSquareUnit.value
      if (kpiAggregationResultPerSquareUnit.value.length === 0) {
        return null
      }
    }

    const assets: Asset[] = []

    for (const result of stateKPI) {
      if (result?.project_results?.length === 0) { continue }

      for (const projectResult of result.project_results!) {
        const currentAssetIndex = assets.findIndex((asset) => asset.id === projectResult.project.id)
        const buildingComponent: ComponentInProjectWithContext = vuexStore.getters['building_analyses/buildingComponentOfProject'](projectResult.project.id)
        const projectWithContext: ProjectWithContext|null = vuexStore.getters['projects/project'](projectResult.project.id)
        const floorArea = getComponentAttribute(buildingComponent, 'B+NFA')?.value ?? ''
        const buildingType = getComponentAttribute(buildingComponent, 'B+TYP')?.value ?? ''

        const currentKPI: KPI = {
          name: result.kpi,
          unit: getUnit(result.units ?? ''),
          value: projectResult.aggregation_value,
        }

        if (currentAssetIndex !== -1) {
          assets[currentAssetIndex].kpis[result.kpi!] = {
            ...currentKPI,
          }
        } else {
          const asset: Asset = {
            city: projectWithContext?.project?.address ?? '',
            floorArea,
            id: projectResult.project.id!,
            kpis: {
              [result.kpi!]: {
                ...currentKPI,
              },
            },
            name: projectResult.project.name!,
            type: buildingType,
          }

          assets.push(asset)
        }
      }
    }

    assets.sort((a, b) => a.name.localeCompare(b.name))

    return assets
  })

  const getPortfolioKpis: ComputedRef<(kpiType: 'absolute' | 'perSquareUnit') => Record<'sum'|'avg', PortfolioAsset> | null> = computed(() => (kpiType: 'absolute' | 'perSquareUnit') => {
    let stateKPI: KPIAggregationResult[]

    if (kpiType === 'absolute') {
      stateKPI = kpiAggregationResultAbsolute.value
      if (stateKPI.length === 0) {
        return null
      }
    } else {
      stateKPI = kpiAggregationResultPerSquareUnit.value
      if (stateKPI.length === 0) {
        return null
      }
    }

    const kpiAggregationResult = stateKPI

    const sumPortfolioKpis: PortfolioAsset = {
      floorArea: getPortfolioFloorArea(vuexStore.getters['projects/getProjectsIds']),
      kpis: {},
      name: 'Portfolio',
      totalProjects: vuexStore.getters['projects/getProjectsIds'].length ?? 0,
      totalProjectsWithTranslation: `${vuexStore.getters['projects/getProjectsIds'].length ?? 0} ${i18n.global.t('notifications.resources.assets')}`,
    }

    for (const kpiResult in kpiAggregationResult) {
      const currentKpiResult = kpiAggregationResult[kpiResult]
      if (!currentKpiResult) continue

      sumPortfolioKpis.kpis[currentKpiResult.kpi!] = {
        name: currentKpiResult.kpi,
        originalAggregationType: currentKpiResult.portfolio_result?.aggregation,
        totalProjects: currentKpiResult.portfolio_result?.total_projects ?? 0,
        unit: getUnit(currentKpiResult.units ?? ''),
        value: currentKpiResult.portfolio_result?.aggregation_value ?? 0,
      }
    }

    const avgPortfolioKpis: PortfolioAsset = structuredClone(sumPortfolioKpis)

    for (const kpi in avgPortfolioKpis.kpis) {
      const currentKpi = avgPortfolioKpis.kpis[kpi]
      if (!currentKpi.totalProjects || !currentKpi.value || currentKpi.originalAggregationType === 'mean') continue
      avgPortfolioKpis.kpis[kpi] = {
        ...avgPortfolioKpis.kpis[kpi],
        value: currentKpi.value / currentKpi.totalProjects,
      }
    }

    return {
      avg: avgPortfolioKpis,
      sum: sumPortfolioKpis,
    }
  })

  async function fetchKpiAggregationData (payload: FetchKpiAggregationPayload): Promise<void|null> {
    try {
      isLoadingKpiAggregation.value = true
      const { kpiType = 'absolute' } = payload ?? {}
      let kpis: typeof absoluteKPIs | typeof perSquareUnitKPIs

      if (kpiType === 'absolute') {
        if (kpiAggregationResultAbsolute.value.length > 0) return null
        kpis = absoluteKPIs
      } else {
        if (kpiAggregationResultPerSquareUnit.value.length > 0) return null
        kpis = perSquareUnitKPIs
      }

      const projectsIds: number[] = validateNonEmptyArray(vuexStore.getters['projects/getProjectsIds'])
      let getKpisRequests: Promise<KPIAggregationResult>[] = []
      const user: Readonly<User> = validateNotNullish(userStore.userDetails)

      if (Object.keys(vuexStore.state.building_analyses.buildingComponents).length === 0) {
        const loadBuildingComponents = projectsIds.map((projectId) => {
        // the building components need to be populated for the net floor area and
        // building type attributes to be available
          return vuexStore.dispatch('building_analyses/fetchBuildingComponentForProject', projectId)
        })
        await Promise.all(loadBuildingComponents)
      }

      getKpisRequests = kpis.map((kpi) => {
        return analyticsApiStore.getKpiAggregation({
          currencySystem: user?.currency_system,
          end: payload?.end,
          kpi,
          projectAggregation: kpi === 'productivity' || kpi === 'technical_availability' ? 'mean' : 'sum',
          projectIds: projectsIds,
          start: payload?.start,
          timeAggregation: kpi === 'productivity' || kpi === 'technical_availability' ? 'mean' : 'sum',
          unitsSystem: user?.units_system,
        },
        payload?.options)
      })
      const kpiAggregationResponse: PromiseSettledResult<KPIAggregationResult>[] = await Promise.allSettled(getKpisRequests)

      const kpiAggregationResult = kpiAggregationResponse.reduce((acc: KPIAggregationResult[], kpiAggregation: PromiseSettledResult<KPIAggregationResult>) => {
        if (kpiAggregation.status === 'fulfilled') {
          validateNotNullish(kpiAggregation.value)
          acc.push(kpiAggregation.value)
        }
        return acc
      }, [] as KPIAggregationResult[])

      if (kpiType === 'absolute') {
        kpiAggregationResultAbsolute.value = kpiAggregationResult as KPIAggregationResult[]
      } else {
        kpiAggregationResultPerSquareUnit.value = kpiAggregationResult as KPIAggregationResult[]
      }
    } catch (error) {
      showErrorNotification(`${i18n.global.t('notifications.errors.fetch', { resource: i18n.global.t('notifications.resources.analysis_results'), supportEmail: texts.emailSupport })}`)
      reportError(error)
    } finally {
      isLoadingKpiAggregation.value = false
    }
  }

  function clearState (): void {
    kpiAggregationResultAbsolute.value = []
    kpiAggregationResultPerSquareUnit.value = []
  }

  return {
    aggregationType,
    assets,
    clearState,
    dateRange,
    fetchKpiAggregationData,
    getPortfolioKpis,
    isLoadingKpiAggregation,
    isPerSquareUnit,
    kpiAggregationResultAbsolute,
    kpiAggregationResultPerSquareUnit,
  }
})

if (import.meta.hot) {
  import.meta.hot.accept(acceptHMRUpdate(useReportingStore, import.meta.hot))
}
