import type { ComponentWithResults, PaginationMeta } from '@aedifion.io/aedifion-api'
import { computed, reactive, toRef, watch } from 'vue'
import { GetAnalyticsProjectComponentsPayload, useAnalyticsApiStore } from '@aedifion.io/pinia-aedifion-api-stores'
import { COMPONENT_LIST_ITEMS_PER_PAGE } from '@/settings'
import debounce from 'lodash.debounce'
import { defineStore } from 'pinia'
import i18n from '@/i18n'
import { mapComponentWithResults } from '@/vuex/components_in_project/getters'
import { reportError } from '@/utils/helpers/errors'
import { showErrorNotification } from '@/utils/helpers/notifications'
import texts from '@theme/texts'
import { useAppStore } from '@/stores/app'

export const useComponentsInProjectStore = defineStore('componentsInProject', () => {
  const appStore = useAppStore()
  const analyticsApiStore = useAnalyticsApiStore()

  type State = {
    componentsInProjectMeta: PaginationMeta|null,
    componentsInProjectWithResults: ComponentWithResults[]|null,
    loading: boolean,
    search: string
  }

  const state: State = reactive({
    componentsInProjectMeta: null,
    componentsInProjectWithResults: null,
    loading: false,
    search: '',
  })

  const componentsInProjectListItems = computed(() => {
    if (state.componentsInProjectWithResults === null) {
      return []
    }

    return state.componentsInProjectWithResults.map(mapComponentWithResults)
  })

  const canFetchMoreComponentsInProject = computed(() => {
    if (state.componentsInProjectWithResults) {
      return state.componentsInProjectWithResults.length < state.componentsInProjectMeta!.total_items
    }

    return true
  })

  async function searchComponentsInProjectWithResults () {
    clearStore()

    await fetchComponentsInProjectWithResults()
  }

  const debouncedSearchComponentsInProjectWithResults = debounce(searchComponentsInProjectWithResults, 500)

  async function fetchComponentsInProjectWithResults () {
    if (!canFetchMoreComponentsInProject.value || state.loading) {
      return
    }

    state.loading = true
    try {
      const payload: GetAnalyticsProjectComponentsPayload = {
        page: (state.componentsInProjectMeta?.current_page ?? 0) + 1,
        perPage: COMPONENT_LIST_ITEMS_PER_PAGE,
        projectId: appStore.projectId,
        search: state.search,
      }
      const componentsApiResponse = await analyticsApiStore.getProjectComponents(payload)

      if (state.componentsInProjectWithResults === null) {
        state.componentsInProjectWithResults = componentsApiResponse.items!
      } else {
        state.componentsInProjectWithResults.push(...componentsApiResponse.items!)
      }
      state.componentsInProjectMeta = componentsApiResponse.meta!
    } catch (error) {
      showErrorNotification(`${i18n.global.t('notifications.errors.components.fetch', { supportEmail: texts.emailSupport })}`)
      reportError(error)
    } finally {
      state.loading = false
    }
  }

  function clearStore () {
    state.componentsInProjectWithResults = null
    state.componentsInProjectMeta = null
  }

  watch(() => appStore.projectId, () => {
    clearStore()
  })

  watch(() => state.search, () => {
    debouncedSearchComponentsInProjectWithResults()
  })

  return {
    componentsInProject: componentsInProjectListItems,
    fetchComponentsInProjectWithResults,
    loading: toRef(state, 'loading'),
    search: toRef(state, 'search'),
  }
})
