import { AIControlsState, ControlsDataPoint } from './types'
import { MutationTree } from 'vuex'

export default {

  SET_CONTROLS_STATUS: (state: AIControlsState, controlsStatus: number) => (state.controlsStatus = controlsStatus),

  SET_DATA_POINTS: (state: AIControlsState, dataPoints: ControlsDataPoint[]) => (state.dataPoints = dataPoints),

  SET_LOADING_CONTROLS_STATUS: (state: AIControlsState, loading: boolean) => (state.loadingControlsStatus = loading),

  SET_LOADING_DATA_POINTS: (state: AIControlsState, loading: boolean) => (state.loadingDataPoints = loading),

  SET_SHOW_COCKPIT_NUDGE: (state: AIControlsState, showCockpitNudge: boolean) => (state.showCockpitNudge = showCockpitNudge),

} as MutationTree<AIControlsState>
