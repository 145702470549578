<script setup lang="ts">
import { computed } from 'vue'
import { ControlsAppStatus } from '@aedifion.io/pinia-aedifion-api-stores'
import { useAIControlsLoggingAppsStore } from '@/stores/views/AIControls/Logging/apps'
import { useI18n } from 'vue-i18n'

interface Props {
  appId: string,
  appStatus: number
}

const props = defineProps<Props>()

const { t } = useI18n()
const appsStore = useAIControlsLoggingAppsStore()

const isSwitchOn = computed<boolean>(() => {
  return props.appStatus >= 200 && props.appStatus < 300
})

const switchColor = computed<string>(() => {
  if (isSwitchOn.value) {
    return 'success'
  } else {
    return 'neutral'
  }
})

const switchDisabled = computed<boolean>(() => {
  return props.appStatus !== ControlsAppStatus.RUNNING && props.appStatus !== ControlsAppStatus.STOPPED
})

const tooltip = computed<string>(() => {
  switch (props.appStatus) {
    case ControlsAppStatus.RUNNING:
      return t('tooltip.turn_off')
    case ControlsAppStatus.STOPPED:
      return t('tooltip.turn_on')
    default:
      return t('tooltip.unavailable')
  }
})

function toggleAppStatus () {
  appsStore.toggleControlsApp(props.appId, props.appStatus)
}
</script>

<template>
  <v-tooltip
    location="bottom"
    content-class="aedifion-tooltip"
  >
    <template #activator="{ props: tooltipProps }">
      <div
        v-bind="tooltipProps"
      >
        <v-switch
          class="app-toggle tw-mr-2"
          :color="switchColor"
          :disabled="switchDisabled"
          inset
          :model-value="isSwitchOn"
          @click.stop="toggleAppStatus()"
        />
      </div>
    </template>
    <span v-text="tooltip" />
  </v-tooltip>
</template>

<style lang="sass" scoped>
</style>

<i18n lang="json" locale="de">
{
  "tooltip": {
    "unavailable": "Zustand verhindert Änderung der App",
    "turn_off": "Klicken um die App zu deaktivieren",
    "turn_on": "Klicken um die App zu aktivieren"
  }
}
</i18n>
<i18n lang="json" locale="en">
{
  "tooltip": {
    "unavailable": "Status prohibits toggling app",
    "turn_off": "Click to turn off",
    "turn_on": "Click to turn on"
  }
}
</i18n>

<style lang="sass" scoped>
.app-toggle
  & :deep(.v-input__control)
    width: 12px !important
    height: 12px !important
  & :deep(.v-input--selection-controls__ripple)
    width: 20px !important
    height: 20px !important
  & :deep(.v-input--selection-controls__input)
    width: 26px !important
    height: 16px !important
  & :deep(.v-input--switch__thumb)
    width: 12px !important
    height: 12px !important
    top: calc(50% - 8px)
    transform: translate(20px, 0) !important
  & :deep(.v-input--switch__track)
    width: 26px !important
    height: 16px !important
    top: calc(50% - 10px)

:deep(.app-toggle.v-input--switch--inset.v-input--is-dirty .v-input--switch__thumb)
  transform: translate(8px, 0) !important
:deep(.app-toggle.v-input--switch--inset.v-input--is-dirty .v-input--selection-controls__ripple)
  transform: translate(10px, 5px) !important
:deep(.app-toggle.v-input--switch--inset .v-input--switch__thumb)
  transform: translate(-2px, 0) !important
:deep(.app-toggle.v-input--switch--inset .v-input--selection-controls__ripple)
  transform: translate(0px, 5px) !important

:deep(.app-toggle .v-switch__track)
  height: 16px,
  width: 26px,
  min-width: 26px
:deep(.app-toggle .v-switch__thumb)
  height: 12px,
  width: 12px,
  transform: none
</style>
