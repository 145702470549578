<script setup lang="ts">
import { computed, ref, watch } from 'vue'
import ConfirmationDialog from '@/components/ConfirmationDialog.vue'
import { getColorOfButtonByControlsStateCode } from '@/utils/helpers/controls'
import { useAlgorithmsStore } from '@/stores/views/AIControls/algorithms'
import { useI18n } from 'vue-i18n'
import { useStore } from '@/vuex'

interface Props {
  statusCode?: number|null
}

const props = withDefaults(defineProps<Props>(), {
  statusCode: null,
})

const APPS_RELOAD_FETCH_DELAY = 5 // seconds

const vuexStore = useStore()

const { t } = useI18n()

const countDownValue = ref(0)

const interval = ref<ReturnType<typeof setInterval> | null>(null)

const isCountDownActive = ref(false)

const operation = ref<'controls_on' | 'controls_off'>('controls_off')

const showConfirmationDialog = ref(false)

const showConfirmationDialogMainContent = ref(true)

const switchStatus = ref<null | boolean>(null)

const buttonColor = computed<string>(() => {
  return getColorOfButtonByControlsStateCode(props.statusCode)
})

const dialogButtonAndProgressColor = computed<string>(() => {
  let statusCode
  if (operation.value === 'controls_on') {
    statusCode = 102
  } else if (operation.value === 'controls_off') {
    statusCode = 202
  } else {
    statusCode = null
  }
  return getColorOfButtonByControlsStateCode(statusCode)
})

const confirmationDialogButtonText = computed<string>(() => operation.value === 'controls_on' ? 'actions.switch_on' : 'actions.switch_off')

const confirmationDialogWidth = computed<number>(() => isCountDownActive.value || !showConfirmationDialogMainContent.value ? 560 : 857)

const countDownTextValue = computed<number>(() => Math.abs(5 - countDownValue.value))

const isOptimizationActive = computed<boolean>(() => props.statusCode === 202)

const modalIcon = computed<string>(() => operation.value === 'controls_on' ? 'fa:fas fa-circle-check' : 'fa:fas fa-do-not-enter')

const buttonLabel = computed<string>(() => `button_text.${isOptimizationActive.value ? 'disable_apps' : 'enable_apps'}`)

watch(countDownValue, async (newValue) => {
  if (newValue >= APPS_RELOAD_FETCH_DELAY) {
    await vuexStore.dispatch('ai_controls/fetchAndSetControlsAppsStatus')
    await useAlgorithmsStore().fetchAllAlgorithmsActivities()
  }
})

watch(() => props.statusCode, (newStatusCode) => {
  if (newStatusCode === null) return

  if (newStatusCode === 202) switchStatus.value = true
  else switchStatus.value = false
},
{
  immediate: true,
})

async function changeControlsState () {
  showConfirmationDialogMainContent.value = false
  isCountDownActive.value = true
  countDownValue.value = 0

  interval.value = setInterval(() => {
    if (countDownValue.value >= APPS_RELOAD_FETCH_DELAY) {
      isCountDownActive.value = false
      return
    }
    countDownValue.value++
  }, 1000)

  await vuexStore.dispatch(
    'ai_controls/toggleControlsAppsStatus',
    !isOptimizationActive.value,
  )
}

function handleCloseDialog () {
  showConfirmationDialog.value = false

  if (interval.value) clearInterval(interval.value)
}

function handleStatusControlButtonClick () {
  if (props.statusCode === null) return

  operation.value = isOptimizationActive.value ? 'controls_off' : 'controls_on'
  showConfirmationDialogMainContent.value = true
  showConfirmationDialog.value = true
}
</script>

<template>
  <div>
    <v-btn
      variant="flat"
      :color="buttonColor"
      class="ml-auto"
      data-testid="status-controls-button"
      :disabled="statusCode === null"
      append-icon="fa-regular fa-angle-down"
      @click="handleStatusControlButtonClick"
    >
      {{ t(buttonLabel) }}
    </v-btn>
    <ConfirmationDialog
      v-model="showConfirmationDialog"
      :accept-button-color="dialogButtonAndProgressColor"
      :accept-button-text-key="confirmationDialogButtonText"
      :card-actions-position-bottom="false"
      :dialog-width="confirmationDialogWidth"
      :show-main-content="showConfirmationDialogMainContent"
      show-contact-info
      :title="t(`confirmation_dialog.title.${operation}`)"
      @confirmation-dialog:accept="changeControlsState"
      @confirmation-dialog:cancel="showConfirmationDialog = false"
    >
      <div
        class="mt-2 mb-6"
        v-html="t(`confirmation_dialog.text.${operation}`)"
      />
      <template #warning>
        <div class="alert-container d-flex flex-row align-center">
          <p>{{ t(`confirmation_dialog.warning.${operation}`) }}</p>
        </div>
      </template>
      <template #alternativeContent>
        <div
          v-if="isCountDownActive"
          class="py-10"
        >
          <v-card-text
            class="text-h6 mb-8 text-center text-neutral-darken3"
          >
            {{ t(`confirmation_dialog.progress.${operation}`) }}
          </v-card-text>
          <v-progress-circular
            class="mx-auto text-center d-flex flex-column"
            :color="dialogButtonAndProgressColor"
            :rotate="-90"
            :size="133"
            :model-value="countDownValue * 100 / APPS_RELOAD_FETCH_DELAY"
            :width="7"
          >
            <span
              class="font-weight-bold text-h3 text-black mt-n4"
              style="position: relative"
            >
              {{ countDownTextValue }}
              <span
                class="
                      text-subtitle-1 text-capitalize
                      text-neutral-darken2
                      seconds-text
                    "
              >
                {{ t("seconds") }}
              </span>
            </span>
          </v-progress-circular>
        </div>
        <div
          v-else
          class="d-flex flex-column justify-center align-center overflow-hidden tw-min-h-[298px]"
        >
          <v-card-text
            class="text-h6 text-neutral-darken4 tw-flex tw-flex-col tw-justify-center"
            data-cy="confirmation-dialog-done-text"
          >
            <v-icon
              class="mx-auto tw-w-full d-block mb-4"
              :color="dialogButtonAndProgressColor"
              size="32"
            >
              {{ modalIcon }}
            </v-icon>
            <span class="d-block">{{ t(`confirmation_dialog.done.${operation}`) }}</span>
          </v-card-text>
          <v-btn
            class="ml-auto remove-hover__btn tw-absolute tw-top-0 tw-right-2"
            color="neutral-darken4"
            data-cy="confirmation-dialog-close-btn"
            variant="text"
            icon
            @click="handleCloseDialog"
          >
            <v-icon>fa:fal fa-circle-xmark</v-icon>
          </v-btn>
        </div>
      </template>
    </ConfirmationDialog>
  </div>
</template>

<style lang="sass" scoped>
.seconds-text
  position: absolute
  left: 50%
  bottom: -70%
  transform: translate(-50%, -50%)
</style>

<!-- Added this eslint ignore because the locales are actually being used, but the are dynamic and the eslint plugin cannot detect that -->
<!-- eslint-disable @intlify/vue-i18n/no-unused-keys -->
<i18n lang="json" locale="de">
  {
    "button_text": {
      "disable_apps": "Apps ausschalten",
      "enable_apps": "Apps einschalten"
    },
    "confirmation_dialog": {
      "title": {
        "controls_on": "Optimale Regelung einschalten",
        "controls_off": "Optimale Regelung ausschalten"
      },
      "text": {
        "controls_on": "Sollen <b>alle</b> optimalen Regelungen, die von aedifion eingerichtet wurden, eingeschaltet werden?",
        "controls_off": "Sollen <b>alle</b> optimalen Regelungen, die von aedifion eingerichtet wurden, ausgeschaltet werden?"
      },
      "progress": {
        "controls_on": "Die Optimierung beginnt in:",
        "controls_off": "Die Optimierung wird heruntergefahren in:"
      },
      "done": {
        "controls_on": "Die Optimierung wurde eingeschaltet.",
        "controls_off": "Die Optimierung wurde ausgeschaltet. "
      },
      "warning": {
        "controls_on": "Durch Klicken auf \"Einschalten\" beeinflusst du den Betrieb des Gebäudes. Wenn du unsicher bist, kontaktiere bitte aedifion.",
        "controls_off": "Durch Klicken auf \"Ausschalten\" beeinflusst du den Betrieb des Gebäudes. Wenn du unsicher bist, kontaktiere bitte aedifion."
      }
    },
    "seconds": "Sekunden"
  }
  </i18n>
  <!-- eslint-disable @intlify/vue-i18n/no-unused-keys -->
  <i18n lang="json" locale="en">
  {
    "button_text": {
      "disable_apps": "Disable apps",
      "enable_apps": "Enable apps"
    },
    "confirmation_dialog": {
      "title": {
        "controls_on": "Switch on optimization",
        "controls_off": "Switch off optimization"
      },
      "text": {
        "controls_on": "Shall <b>all</b> optimal controls installed by aedifion be switched on?",
        "controls_off": "Shall <b>all</b> optimal controls installed by aedifion be switched off?"
      },
      "progress": {
        "controls_on": "Optimization is going to start in:",
        "controls_off": "Optimization shutting down in:"
      },
      "done": {
        "controls_on": "Optimization ongoing!",
        "controls_off": "Optimization is stopped. Local controls activated!"
      },
      "warning":{
        "controls_on": "By clicking \"Switch on\", you will change the operation of the building. If you are uncertain, please contact aedifion first.",
        "controls_off": "By clicking \"Switch off\", you will change the operation of the building. If you are uncertain, please contact aedifion first."
      }
    },
    "seconds": "seconds"
   }
  </i18n>
