<script setup lang="ts">
import { computed, onBeforeUnmount, onMounted, ref, watch } from 'vue'
import { NavigationGuardNext, onBeforeRouteUpdate, RouteLocationNormalized, useRoute } from 'vue-router'
import { storeToRefs } from 'pinia'
import { ControlsAppStatus } from '@aedifion.io/pinia-aedifion-api-stores'
import { addLeaderLine, removeLeaderLine } from '@/views/AIControls/App/leaderLines'
import PageHeader from '@/components/PageHeader.vue'
import ControlsAlgorithmCard from '@/views/AIControls/ControlsAlgorithmCard.vue'
import ControlsAppSelector from '@/views/AIControls/ControlsAppSelector.vue'
import PinCardColumn from '@/views/AIControls/App/PinCardColumn.vue'
import { useAppStore } from '@/stores/app'
import { isActiveAppStatus } from '@/stores/views/AIControls/algorithms'
import { useAIControlsAppStore } from '@/stores/views/AIControls/App/app'
import LeaderLine from 'leader-line-new'
import { useSelectControlsApp } from '../Composables/useSelectControlsApp'

const appStore = useAppStore()
const { currentProjectControlsAppCount } = storeToRefs(appStore)
const aiControlsAppStore = useAIControlsAppStore()
const { controlsApp, isLoading: isAiConrolsAppStoreLoading } = storeToRefs(aiControlsAppStore)
const route = useRoute()

const appId = computed<string>(() => route.params.appId as string)

onMounted(() => {
  aiControlsAppStore.fetchApp(appId.value)
})

onBeforeRouteUpdate(async (to: RouteLocationNormalized, from: RouteLocationNormalized, next: NavigationGuardNext) => {
  if (to.params.project && from.params.project && to.params.project !== from.params.project) {
    // adapt route param appId since fetchApp reads from route
    if (to.params.appId && from.params.appId && to.params.appId === from.params.appId) { // project changed by menu
      route.params.appId = ''
    } else { // browser navigation back/forward
      route.params.appId = to.params.appId
    }
    if (await appStore.projectHasControlsApp(parseInt(to.params.project as string))) { // need to fire again since we can't await here for the fetch from setProjectId
      next()
    } else {
      next({ name: 'ai-controls', params: { project: appStore.projectId.toString() } }) // navigate to algorithm overview page
    }
  } else {
    await aiControlsAppStore.fetchApp(to.params.appId as string)
    next()
  }
})

watch(currentProjectControlsAppCount, () => { // on page reload wait for currentProjectControlsAppCount to be set
  aiControlsAppStore.fetchApp(appId.value) // won't fetch if currentProjectControlsAppCount is reset to null
})
// #region CONTROL APPS SELECTOR
const { debouncedSearchApps, fetchMoreControlsApps, selectControlsApp, apps, search } = useSelectControlsApp()
// #endregion

const isLoading = computed(() => currentProjectControlsAppCount.value === null || isAiConrolsAppStoreLoading.value)

const algorithmCard = ref<InstanceType<typeof ControlsAlgorithmCard> | null>(null)
const inputPinCardColumn = ref<InstanceType<typeof PinCardColumn> | null>(null)
const outputPinCardColumn = ref<InstanceType<typeof PinCardColumn> | null>(null)

const yOffset = 30

function removeAllLeaderLines () {
  inputPinCardColumn.value?.cards.forEach((card) => {
    removeLeaderLine(card)
  })

  outputPinCardColumn.value?.cards.forEach((card) => {
    removeLeaderLine(card)
  })
}

watch (() => inputPinCardColumn.value?.cards, (a, b) => {
  const inserted = a?.filter((card) => !b?.includes(card))
  const removed = b?.filter((card) => !a?.includes(card))
  inserted?.forEach((card) => {
    addLeaderLine(
      card,
      LeaderLine.pointAnchor(algorithmCard.value?.$el, { x: 0, y: yOffset }),
      card,
      isActiveAppStatus(controlsApp.value?.status.code as ControlsAppStatus),
    )
  })
  removed?.forEach((card) => removeLeaderLine(card))
})

watch (() => outputPinCardColumn.value?.cards, (a, b) => {
  const inserted = a?.filter((card) => !b?.includes(card))
  const removed = b?.filter((card) => !a?.includes(card))
  inserted?.forEach((card) => {
    addLeaderLine(
      LeaderLine.pointAnchor(algorithmCard.value?.$el, { x: '100%', y: yOffset }),
      card,
      card,
      isActiveAppStatus(controlsApp.value?.status.code as ControlsAppStatus),
    )
  })
  removed?.forEach((card) => removeLeaderLine(card))
})

onBeforeUnmount(() => {
  removeAllLeaderLines()
})
</script>

<template>
  <div class="layout-wrapper text-neutral-darken1 tw-text-sm">
    <PageHeader title-key="links.meta.title.ai_controls_app">
      <ControlsAppSelector
        v-model:search="search"
        class="tw-ml-auto"
        :selected-controls-app="route.params.appId as string ?? null"
        :show-apps-state="false"
        :select-first-item="false"
        :control-apps="apps"
        :project-id="appStore.projectId"
        @fetch-more-controls-apps="fetchMoreControlsApps"
        @search-controls-apps="debouncedSearchApps"
        @select-controls-app="selectControlsApp"
      >
        <template #item-prepend="{ item }">
          <v-icon
            start
            size="small"
            :color="item.raw.custom?.icon.color"
          >
            {{ item.raw.custom?.icon.name }}
          </v-icon>
        </template>
        <template #item-action="{ item }">
          <v-btn
            variant="text"
            class="tw-ml-auto tw-p-0 tw-uppercase text-neutral-darken1"
          >
            <span class="tw-mr-4">{{ item.raw.custom?.algorithm }}</span>
            <v-icon color="neutral-darken4">
              fa:fal fa-arrow-right-long
            </v-icon>
          </v-btn>
        </template>
      </ControlsAppSelector>
    </PageHeader>
    <div class="graph-container">
      <span class="column-title-input tw-mb-4">Input</span>
      <PinCardColumn
        ref="inputPinCardColumn"
        class="pin-card-list-input"
        collection="input"
        :controls-app="isLoading ? undefined : controlsApp"
      />
      <span class="column-title-app tw-mb-4">App</span>
      <ControlsAlgorithmCard
        ref="algorithmCard"
        :algorithm="isLoading ? undefined : controlsApp?.algorithm.toLowerCase()"
        class="app-container"
        :is-active="isActiveAppStatus(controlsApp?.status.code)"
        :title="controlsApp?.algorithm === 'custom' ? controlsApp?.name : ''"
        :description="controlsApp?.algorithm === 'custom' ? controlsApp?.description : ''"
        is-big-title
      />
      <span class="column-title-output tw-mb-4">Output</span>
      <PinCardColumn
        ref="outputPinCardColumn"
        class="pin-card-list-output"
        collection="output"
        :controls-app="isLoading ? undefined : controlsApp"
      />
    </div>
  </div>
</template>

<style lang="sass" scoped>
.layout-wrapper
  font-feature-settings: 'cv01' on, 'ss01' on, 'ss04' on

.graph-container
  display: grid
  width: 100%
  max-width: 1640px

  margin: auto

  grid-template-columns: minmax(250px, 6fr) minmax(40px, 1fr) minmax(min-content, 520px) minmax(40px, 1fr) minmax(250px, 6fr)
  grid-template-areas: "title-input . title-app . title-output" "pin-list-input . app . pin-list-output"

.column-title-input
  grid-area: title-input
.column-title-app
  grid-area: title-app
.column-title-output
  grid-area: title-output
.app-container
  grid-area: app
  min-width: 240px
.pin-card-list-input
  grid-area: pin-list-input
.pin-card-list-output
  grid-area: pin-list-output
</style>
