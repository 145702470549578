import { GetDatapointTimeseriesPayload, GetSetpointsPayload, PostSetpointPayload, useDatapointApiStore, useProjectApiStore } from '@aedifion.io/pinia-aedifion-api-stores'
import { SetpointReference, SetpointReferences, TimeseriesWithContext } from '@aedifion.io/aedifion-api'
import { showErrorNotification, showSuccessNotification } from '@/utils/helpers/notifications'
import { defineStore } from 'pinia'
import i18n from '@/i18n'
import { ref } from 'vue'
import { reportError } from '@/utils/helpers/errors'
import texts from '@theme/texts'
import { useAppStore } from '../app'

export const useSetpointEditorStore = defineStore('setpointEditor', () => {
  const appStore = useAppStore()
  const datapointApiStore = useDatapointApiStore()
  const projectApiStore = useProjectApiStore()

  const currentManualSetpoint = ref<SetpointReference|null>(null)
  const currentSetpointValue = ref<string|null>(null)
  const loadingCurrentSetpointValue = ref(false)
  const loadingSetpointHistory = ref(false)
  const setpointHistory = ref<SetpointReferences | null>(null)

  async function fetchCurrentManualSetpoint (dataPointID: string) {
    try {
      const params: GetSetpointsPayload = {
        filter: `dataPointID=${dataPointID}`,
        page: 1,
        perPage: 1,
        projectId: appStore.projectId,
      }
      const result: SetpointReferences = await projectApiStore.getSetpoints(params, { storeResult: false })
      currentManualSetpoint.value = Array.isArray(result.setpoints) && result.setpoints.length > 0 ? result.setpoints[0] : null
    } catch (error) {
      showErrorNotification(`${i18n.global.t('notifications.errors.fetch', { resource: 'Setpoints', supportEmail: texts.emailSupport })}`)
      reportError(error)
    }
  }

  async function fetchCurrentSetpointValue (dataPointID: string) {
    try {
      loadingCurrentSetpointValue.value = true
      const params: GetDatapointTimeseriesPayload = {
        datapointId: dataPointID,
        end: new Date(),
        max: 1,
        projectId: appStore.projectId,
        start: undefined,
      }
      const result: TimeseriesWithContext = await datapointApiStore.getTimeseries(params, { storeResult: false })
      currentSetpointValue.value = Array.isArray(result.data) && result.data.length > 0 ? String(result.data![0].value!) : null
    } catch (error) {
      showErrorNotification(`${i18n.global.t('notifications.errors.fetch', { resource: 'Setpoints', supportEmail: texts.emailSupport })}`)
      reportError(error)
    } finally {
      loadingCurrentSetpointValue.value = false
    }
  }

  async function fetchSetpointHistory (dataPointID: string, itemsPerPage: number, page?: number) {
    try {
      loadingSetpointHistory.value = true
      const params: GetSetpointsPayload = {
        filter: `dataPointID=${dataPointID}`,
        page: page ?? (projectApiStore.setpoints?.meta?.current_page ?? 1),
        perPage: itemsPerPage,
        projectId: appStore.projectId,
      }
      setpointHistory.value = await projectApiStore.getSetpoints(params, { storeResult: false })
    } catch (error) {
      showErrorNotification(`${i18n.global.t('notifications.errors.fetch', { resource: 'Setpoints', supportEmail: texts.emailSupport })}`)
      reportError(error)
    } finally {
      loadingSetpointHistory.value = false
    }
  }

  async function releaseSetpoint (dataPointID: string) {
    try {
      const params: PostSetpointPayload = {
        acked: false,
        datapointId: dataPointID,
        dryrun: false,
        priority: 13,
        projectId: appStore.projectId,
        value: 'null',
      }
      await datapointApiStore.postSetpoint(params)
      showSuccessNotification(i18n.global.t('notifications.success.setpoint.release') as string)
    } catch (error) {
      showErrorNotification(`${i18n.global.t('notifications.errors.fetch', { resource: 'Setpoints', supportEmail: texts.emailSupport })}`)
      reportError(error)
    }
  }

  async function writeSetpoint (dataPointID: string, value: string) {
    try {
      const params: PostSetpointPayload = {
        acked: false,
        datapointId: dataPointID,
        dryrun: false,
        priority: 13,
        projectId: appStore.projectId,
        value,
      }
      await datapointApiStore.postSetpoint(params)
      showSuccessNotification(i18n.global.t('notifications.success.setpoint.write') as string)
    } catch (error) {
      showErrorNotification(`${i18n.global.t('notifications.errors.fetch', { resource: 'Setpoints', supportEmail: texts.emailSupport })}`)
      reportError(error)
    }
  }

  return {
    currentManualSetpoint,
    currentSetpointValue,
    fetchCurrentManualSetpoint,
    fetchCurrentSetpointValue,
    fetchSetpointHistory,
    loadingCurrentSetpointValue,
    loadingSetpointHistory,
    releaseSetpoint,
    setpointHistory,
    writeSetpoint,
  }
})
