<script setup lang="ts">
import { type Asset, type KPI } from '@/stores/views/Reporting/types'
import { computed, ref } from 'vue'
import { formatSubAndSuper, formatValue } from '@/filters/formatting'
import { useDisplay } from 'vuetify'
import { useI18n } from 'vue-i18n'
import useIsReadOnly from '@/composables/useIsReadOnly'
// #region INITIALIZATION OF PROPS AND COMPOSABLES
interface Props {
  description: string
  id?: number,
  isPerSquareUnit: boolean,
  title: string,
  valueTextClass?: 'text-body-1' | 'font-weight-semibold',
  values: Record<string, KPI>|undefined
}

withDefaults(defineProps<Props>(), {
  valueTextClass: 'text-body-1',
})

const { t } = useI18n()
const { isReadOnly } = useIsReadOnly()

const vuetifyDisplay = useDisplay()
// #endregion

// @ts-ignore
const cardInfoDivMinWidth = computed(() => vuetifyDisplay.xl ? '50%' : '60%')

function areAllValuesUndefined (values: Record<Asset['name'], KPI>|undefined) {
  if (!values) return true

  if (typeof values === 'object') {
    return !Object.keys(values).length || Object.values(values).every((kpi: KPI) => kpi.value === undefined)
  }

  return false
}

// #region LOGIC FOR HEADERS HOVER STATE
const isHeaderHovered = ref<boolean>(false)

function toggleHeadersHoverState (value: boolean) {
  isHeaderHovered.value = value
}
// #endregion
</script>

<template>
  <v-hover v-slot="{ isHovering }">
    <div
      v-bind="$attrs"
      class="reporting-card__wrapper"
    >
      <div
        v-if="$slots.headers"
        :class="['header-wrapper', { 'header-wrapper--hover': isHeaderHovered }]"
      />
      <div
        data-testid="reporting-card"
        class="d-flex align-center reporting-card bordered"
      >
        <div
          class="
            mr-6
            pr-0
            px-4
            d-flex
            flex-column
            justify-center
            sticky
          "
        >
          <span
            class="text-neutral-darken4 text-subtitle-2 font-weight-semibold text-truncate"
            data-cy="card-title"
          >
            {{ title }}
          </span>
          <span
            class="text-subtitle-2 text-neutral-darken1 text-truncate font-weight-regular"
            data-cy="card-description"
            v-html="description || 'N/A'"
          />
        </div>
        <div class="empty-div">
          <slot />
        </div>
        <div
          class="ml-auto card-info-div"
        >
          <div
            v-if="!areAllValuesUndefined(values)"
            class="d-flex align-center justify-space-between"
          >
            <span
              v-for="(asset, key, index) in values"
              :key="key"
              class="
                reporting-card__value
                flex-grow-1
                text-neutral-darken2 text-subtitle-2
                mb-0
                align-center
                text-end
                ml-1 ml-md-4 ml-xl-7
              "
              data-cy="card-value"
            >
              <div
                @mouseenter="toggleHeadersHoverState(true)"
                @mouseleave="toggleHeadersHoverState(false)"
              >
                <slot
                  v-if="$slots.headers"
                  data-cy="card-headers"
                  :index="index"
                  name="headers"
                />
              </div>
              <span
                v-if="formatValue(asset.value)"
                :class="[valueTextClass, 'text-neutral-darken3']"
                data-cy="column-value"
              ><span class="kpi-values">
                {{
                  formatValue(asset.value, { displayUnit: false, unit: asset.unit })
                }}
                <span
                  :class="['text-subtitle-2 text-neutral text-end font-weight-regular',{ 'text-neutral-darken2': isHovering }]"
                  data-cy="column-unit"
                >
                  {{
                    formatSubAndSuper(asset.unit ?? '')
                  }}
                </span>
              </span>

              </span>
              <span
                v-else
                :class="['text-subtitle-2 text-neutral text-end', { 'text-neutral-darken2': isHovering }]"
              >N/A</span>
            </span>
          </div>
          <div
            v-else
            class="d-flex align-center"
            data-testid="enter-meter-reading"
          >
            <span class="ml-auto mr-4 text-subtitle-2 font-weight-regular text-neutral-darken1">{{ t('meter_view.enter_meter_reading') }}</span>
            <v-btn
              v-if="!isReadOnly"
              color="primary-darken2"
              class="d-flex align-center mr-n2"
              :to="`/meter/${id}`"
              height="40px"
            >
              <span class="mr-3">{{ t('meter_view.add_meter_data') }}</span>
              <v-icon size="small">
                fa:fal fa-arrow-right
              </v-icon>
            </v-btn>
          </div>
        </div>
      </div>
    </div>
  </v-hover>
</template>

<style lang="sass" scoped>
.reporting-card
 padding: 27px 16px 27px 0
 width: auto
 min-width: 1040px
 height: 54px

.card-info-div
  min-width: v-bind(cardInfoDivMinWidth)

.bordered
  border: 1px solid rgb(var(--v-theme-neutral-lighten1))
  border-left: 0
  background-color: #ffffff

.reporting-card__value
 position: relative
 width: 90px

.sticky
  border: 1px solid rgb(var(--v-theme-neutral-lighten1))
  border-right: 0
  width: fit-content
  max-width: 240px
  left: 0
  z-index: 5
  background: white
  height: 56px

.kpi-values
  font-feature-settings: 'tnum' on, 'lnum' on, 'ss04' on, 'ss01' on, 'cv01' on

/* This specific number was chosen, because at 1320px viewport width, we have the following:
   SideNav-width: 200px;
   margin on the left and right: 80px;
   The left over width is 1320 - 200 - 80 = 1040px which exactly equals the min-width of the card.
   Therefore, below this width, we are applying border right for the sticky class and applying over-flow-x: scroll on the parent.*/

@media screen and (max-width: 1319px)
  .sticky
    border-right: 1px solid rgb(var(--v-theme-neutral-lighten1))
    min-width: 240px
    position: sticky

.empty-div
  width: 80px
  min-width: 80px

.header-wrapper
  height: 32px
  width: 100%
  min-width: 1040px // same min-width as the card
  border-radius: 4px
  border: 1px solid transparent
  background-color: rgb(var(--v-background))

  &:hover
    background-color: rgb(var(--v-theme-neutral-lighten2))

.header-wrapper--hover
  background-color: rgb(var(--v-theme-neutral-lighten2))

</style>
