import { aedifionApi, DataPointWithContext, TimeseriesWithContext } from '@aedifion.io/aedifion-api'
import { AIControlsState, ControlsAppStatus, ControlsDataPoint } from './types'
import { validateNotEqual, validateNotNullish } from '@/utils/helpers/validate'
import { ActionTree } from 'vuex'
import { fetchProjectTimeseries } from '@/utils/helpers/timeseries'
import i18n from '@/i18n'
import { reportError } from '@/utils/helpers/errors'
import { resetStoreState } from './state'
import { RootState } from '../types'
import { sampleDataPoints } from './sampleData'
import { showErrorNotification } from '@/utils/helpers/notifications'
import texts from '@theme/texts'
import { useUserStore } from '@/stores/user'

function combineToControlsDataPoints (dataPoints: DataPointWithContext[], allTimeseries: TimeseriesWithContext[]): ControlsDataPoint[] {
  const result: ControlsDataPoint[] = []
  for (const dataPoint of dataPoints) {
    const timeseries: TimeseriesWithContext|undefined = allTimeseries.find((timeseries: TimeseriesWithContext) => {
      return timeseries.dataPointID === dataPoint.dataPointID
    })!
    result.push({
      dataPoint,
      latestValue: (timeseries && timeseries.data && timeseries.data.length > 0) ? timeseries.data[0].value ?? null : null,
      unitLabelId: timeseries.units ?? null,
    })
  }
  return result
}

export default {
  clear: ({ state }) => {
    resetStoreState(state)
  },

  fetchAndSetControlsAppsStatus: async ({ commit, rootGetters }) => {
    commit('SET_LOADING_CONTROLS_STATUS', true)

    try {
      const projectId = validateNotNullish(
        rootGetters['projects/currentProjectId'] as number|null,
        { errorMessage: i18n.global.t('notifications.errors.no_project_selected') as string },
      )
      const controlApps = await aedifionApi.Controls.getControlsApps(projectId)
      if (controlApps.items.length > 0) {
        commit('SET_SHOW_COCKPIT_NUDGE', false)
        const controlAppsStatus = controlApps.items.map((controlApp) => controlApp.status.code)
        if (controlAppsStatus.some(appStatus => appStatus === ControlsAppStatus.Running)) {
          commit('SET_CONTROLS_STATUS', ControlsAppStatus.Running)
        } else {
          commit('SET_CONTROLS_STATUS', ControlsAppStatus.ReadyToStart)
        }
      } else {
        commit('SET_SHOW_COCKPIT_NUDGE', true)
        commit('SET_CONTROLS_STATUS', ControlsAppStatus.NoControlsAppsFound)
      }
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (error: any) {
      if (error.status === 403) { // This code is returned when controls apps have not been set up for a project
        commit('SET_SHOW_COCKPIT_NUDGE', true)
        commit('SET_CONTROLS_STATUS', ControlsAppStatus.NoControlsAppsFound)
      } else {
        showErrorNotification(i18n.global.t('notifications.errors.datapoints.fetch', { supportEmail: texts.emailSupport }) as string)
        reportError(error)
        commit('SET_CONTROLS_STATUS', null)
      }
    } finally {
      commit('SET_LOADING_CONTROLS_STATUS', false)
    }
  },

  fetchDataPoints: async ({ state, commit, rootGetters }) => {
    if (state.showCockpitNudge) {
      commit('SET_DATA_POINTS', sampleDataPoints())
      return
    }

    try {
      const projectId = validateNotNullish(
        rootGetters['projects/currentProjectId'] as number|null,
        { errorMessage: i18n.global.t('notifications.errors.no_project_selected') as string },
      )
      const userStore = useUserStore()
      const user = validateNotNullish(userStore.userDetails)

      if (state.dataPoints === null) {
        commit('SET_LOADING_DATA_POINTS', true)
      }

      const hashIds: string[] = (await Promise.all([
        aedifionApi.Project.getProjectDatapointsByPage(projectId, 1, 100, 'controls_cockpit_current_values'),
        aedifionApi.Project.getProjectDatapointsByPage(projectId, 1, 100, 'controls_cockpit_savings'),
      ])).map((datapointsList) => datapointsList.idMappings!.map((mapping) => mapping.hash_id) as string[]).flat()

      validateNotEqual(hashIds.length, 0)

      const latestValues: TimeseriesWithContext[] = await fetchProjectTimeseries(projectId, hashIds, user.units_system, user.currency_system)

      const datapointsWithContext: DataPointWithContext[] = await Promise.all(hashIds.map((hashId: string) => {
        return aedifionApi.Datapoint.getDatapoint(hashId, projectId!)
      }))

      const controlsDataPoints: ControlsDataPoint[] = combineToControlsDataPoints(datapointsWithContext, latestValues)

      commit('SET_DATA_POINTS', controlsDataPoints)
    } catch (error) {
      showErrorNotification(`${i18n.global.t('notifications.errors.fetch', { resource: i18n.global.t('notifications.resources.datapoint'), supportEmail: texts.emailSupport })}`)
      reportError(error)
      commit('SET_DATA_POINTS', null)
    } finally {
      commit('SET_LOADING_DATA_POINTS', false)
    }
  },

  toggleControlsAppsStatus: async ({ rootGetters }, controlsEnabled: boolean) => {
    try {
      const projectId = validateNotNullish(
        rootGetters['projects/currentProjectId'] as number|null,
        { errorMessage: i18n.global.t('notifications.errors.no_project_selected') as string },
      )

      await aedifionApi.Controls.postControlsAppsRun(projectId, controlsEnabled)
    } catch (error) {
      const action = controlsEnabled ? i18n.global.t('actions.deactivated') as string : i18n.global.t('actions.activated') as string
      showErrorNotification(`${i18n.global.t('notifications.errors.optimization.toggle', { action, supportEmail: texts.emailSupport })}`)
      reportError(error)
    }
  },
} as ActionTree<AIControlsState, RootState>
