import moment, { Moment } from 'moment'
import i18n from '@/i18n'

export function getDatePartAsString (dateTime: Date|moment.Moment|string): string {
  if (typeof dateTime === 'string') {
    return new Date(dateTime).toISOString().split('T')[0]
  }
  return dateTime.toISOString().split('T')[0]
}

function fixPrefixWordOfDuration (duration: string): string {
  const words = duration.split(' ').slice(-2)
  return `${i18n.global.t('for')} ${words[0]} ${words[1]}`
}

/**
 * Returns the duration of the given date in a humanized format.
 * @param date The duration will be calculated from this date.
 * @returns The duration in a humanized format.
 */
export function getHumanizedDuration (date: Moment): string {
  const now = moment()
  const locale = i18n.global.locale.value
  const duration = moment.duration(now.diff(date))

  moment.locale(locale)

  if (duration.asHours() < 1) {
    return `${i18n.global.t('for')} ${i18n.global.t('recently')}`
  }

  return fixPrefixWordOfDuration(moment(now).add(duration).fromNow(true))
}
