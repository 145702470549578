<script setup lang="ts">
import AppCountBadge from '@/components/CountBadge.vue'
import { getAlgorithmShortcode } from '@/utils/helpers/controls'
import { useI18n } from 'vue-i18n'

interface Props {
  activeApps?: number
  algorithm?: string
  description?: string
  inactiveApps?: number
  truncateDescription?: boolean
  index?: number
  isActive?: boolean
  isBigTitle?: boolean
  title?: string
}

const props = defineProps<Props>()

const AVAILABLE_GRADIENTS = 8

const { t } = useI18n()

const gradientNr = props.index === undefined ? Math.floor(Math.random() * AVAILABLE_GRADIENTS) + 1 : props.index % 8 + 1

const random = Math.random() * 100
// @ts-expect-error - this is used in the style section only
const styleVars = {
  position25: random < 50 ? 'top' : 'bottom',
  position75: random < 50 ? 'bottom' : 'top',
  startPosition: `${random}%`,
}
</script>

<template>
  <v-card class="px-2 pt-4 pb-2">
    <div
      v-if="!algorithm"
      data-testid="skeleton-loader"
    >
      <v-skeleton-loader
        type="text"
        class="shortcode"
      />
      <v-skeleton-loader type="text" />
      <v-skeleton-loader
        type="text"
        class="description"
      />
    </div>
    <div v-else>
      <v-card-title
        class="tw-flex tw-justify-between tw-items-start tw-gap-2 tw-flex-wrap"
      >
        <div
          class="shortcode tw-text-[28px] tw-uppercase tw-leading-none tw-font-extrabold tw-bg-clip-text tw-text-transparent tw-bg-cover tw-tracking-[-1.12px]"
          :class="activeApps > 0 || isActive ? ['active']: ['tw-grayscale']"
          :style="`background-image: url(/img/mesh-gradients/mesh-gradient-${gradientNr}-compressed.png)`"
          data-testId="shortcode"
        >
          {{ getAlgorithmShortcode(algorithm) }}
        </div>
        <div class="tw-flex tw-gap-2 tw-flex-wrap">
          <AppCountBadge
            v-if="activeApps > 0"
            :count="activeApps"
            :is-active="true"
            :tooltip-text="t('active_apps')"
            data-testid="active-apps-badge"
          />
          <AppCountBadge
            v-if="inactiveApps > 0"
            :count="inactiveApps"
            :is-active="false"
            :tooltip-text="t('inactive_apps')"
            data-testid="inactive-apps-badge"
          />
        </div>
      </v-card-title>
      <v-card-subtitle
        class="tw-whitespace-normal tw-opacity-100 tw-tracking-[0.2px]"
        :class="isBigTitle ? 'tw-text-2xl tw-font-semibold text-neutral-darken4 tw-pt-2 tw-capitalize' : 'tw-text-xs text-neutral-darken1 tw-pb-2'"
      >
        {{ title ? title : t(`ai_controls.algorithm.${algorithm}.title`) }}
      </v-card-subtitle>
      <v-card-text class="tw-text-sm text-neutral-darken3">
        <div
          :class="['algorithm-description', { 'tw-line-clamp-5': truncateDescription }]"
          v-html="description ? description : t(`ai_controls.algorithm.${algorithm}.description`)"
        />
      </v-card-text>
    </div>
  </v-card>
</template>

<style lang="sass" scoped>
@keyframes animation
  from
    background-position-y: v-bind('styleVars.startPosition')
  25%
    background-position-y: v-bind('styleVars.position25')
  75%
    background-position-y: v-bind('styleVars.position75')
  to
    background-position-y: v-bind('styleVars.startPosition')

.v-card
  .v-card-title
    .shortcode
      &.active
        animation: animation 10s infinite linear
:deep(.v-skeleton-loader)
  &.shortcode
    .v-skeleton-loader__text
      @apply tw-h-7
  &.description
    .v-skeleton-loader__text
      @apply tw-h-40

:deep(.algorithm-description)
  p
    &:not(:last-child)
       margin-bottom: 8px !important
 </style>

<i18n locale="de">
{
  active_apps: "Aktive Apps",
  inactive_apps: "Inaktive Apps"
}
</i18n>

<i18n locale="en">
{
  active_apps: "active apps",
  inactive_apps: "inactive apps"
}
</i18n>
