import { Texts } from '../types.js'

export default {
  appName: 'Phoenix Contact',
  companyName: 'Phoenix Contact',
  docs: {
    links: {
      contact: 'https://www.phoenixcontact.com/en-pc/service-and-support/contact'
    },
    variables: {
      domain: 'https://docs.emalyticscloud.com',
      products: {
        analytics: 'analyzer',
        controls: 'control',
        edge_device: 'connector',
        elevate: 'add-on',
        engineering: 'engineering',
        io: 'collector'
      }
    }
  },
  emailSupport: 'support@phoenixcontact.com',
  phoneSupport: '+49 221 98650770',
  supportCallToActionKey: 'support_call_to_action.support'
} as Texts
