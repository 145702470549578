<script setup lang="ts">
import { NavigationGuardNext, onBeforeRouteUpdate, RouteLocationNormalized } from 'vue-router'
import { COCKPIT_NUDGE_GRADIENT } from '@theme/colors'
import EmptyStateForPage from '@/components/EmptyStateForPage.vue'
import FilterBar from './FilterBar.vue'
import LogsTable from './LogsTable.vue'
import { onUnmounted } from 'vue'
import PageHeader from '@/components/PageHeader.vue'
import { useAIControlsLoggingAppsStore } from '@/stores/views/AIControls/Logging/apps'
import { useAIControlsLoggingLogsStore } from '@/stores/views/AIControls/Logging/logs'
import { useAppStore } from '@/stores/app'
import { useI18n } from 'vue-i18n'

const appStore = useAppStore()
const aiControlsLoggingAppsStore = useAIControlsLoggingAppsStore()
const aiControlsLoggingLogsStore = useAIControlsLoggingLogsStore()

const { t } = useI18n()

onBeforeRouteUpdate(async (to: RouteLocationNormalized, from: RouteLocationNormalized, next: NavigationGuardNext) => {
  if (to.params.project && from.params.project && to.params.project !== from.params.project) {
    if (await appStore.projectHasControlsApp(parseInt(to.params.project as string))) {
      aiControlsLoggingAppsStore.clear()
      aiControlsLoggingLogsStore.clear()
      await aiControlsLoggingAppsStore.fetchMoreControlsApps()
      aiControlsLoggingAppsStore.selectFirstControlsApp()
      aiControlsLoggingLogsStore.resetAndFetchLogs()
      next()
    } else {
      next({ name: 'ai-controls', params: { project: appStore.projectId.toString() } })
    }
  } else {
    next()
  }
})

onUnmounted(() => {
  aiControlsLoggingAppsStore.stopAppStatePolling()
})
</script>

<template>
  <div class="layout-wrapper">
    <PageHeader
      sticky
      title-key="links.meta.title.ai_controls_logging"
    >
      <FilterBar
        class="ml-auto"
      />
    </PageHeader>
    <EmptyStateForPage
      v-if="aiControlsLoggingLogsStore.state?.logs?.length === 0"
      class="mt-n8"
      :icon="{
        name: 'fa:fal fa-clock',
        size: '40px',
        color: `linear-gradient(${COCKPIT_NUDGE_GRADIENT[0]}, ${COCKPIT_NUDGE_GRADIENT[1]}, ${COCKPIT_NUDGE_GRADIENT[2]})`
      }"
      :title="t('empty_state.title')"
      :description="t('empty_state.description')"
    />
    <LogsTable
      v-else
    />
  </div>
</template>

<i18n lang="json" locale="de">
{
  "empty_state": {
    "description": "Für die ausgewählte Controls App und den Zeitraum sind keine Logs vorhanden.",
    "title": "Keine Ergebnisse"
  }
}
</i18n>
<i18n lang="json" locale="en">
{
  "empty_state": {
    "description": "There are no logs available for the chosen Controls app and time period.",
    "title": "No results found"
  }
}
</i18n>
