<template>
  <v-card
    class="d-flex flex-row"
    height="225"
    max-height="225"
    min-width="650"
  >
    <v-img
      class="rounded-s rounded-e-0"
      lazy-src="/img/dummy-image.jpg"
      max-height="248px"
      max-width="357px"
      min-height="223px"
      min-width="357px"
      :src="avatarUrl"
      cover
    />
    <div class="flex-grow-1 flex-truncate-fix">
      <div class="d-flex flex-row">
        <div class="flex-truncate-fix pt-4">
          <v-card-title class="d-block text-h2 text-neutral-darken4 text-truncate pb-1">
            {{ project.name }}
          </v-card-title>
          <v-card-subtitle
            v-if="project.address"
            class="text-body-1 text-truncate mt- text-neutral-darken4 pt-2 pb-3"
          >
            {{ project.address }}
          </v-card-subtitle>
        </div>
        <v-btn
          class="mt-4 mr-4 ml-auto text-primary-darken2"
          color="primary-lighten3"
          :to="`/asset-overview/${project.id}/profile`"
        >
          {{ t('actions.more') }}
        </v-btn>
      </div>
      <v-card-text class="mt-2">
        <v-row v-if="loading">
          <v-col cols="6">
            <v-skeleton-loader
              type="text@4"
            />
          </v-col>
          <v-col cols="6">
            <v-skeleton-loader
              type="text@4"
            />
          </v-col>
        </v-row>
        <div
          v-else
          class="d-flex flex-row justify-space-between"
        >
          <div
            v-for="column of requiredAttributeColumns"
            :key="`attribute_column_${column}`"
            class="flex-grow-1"
          >
            <table>
              <tbody>
                <tr
                  v-for="(attribute, index) in attributesForColumn(column)"
                  :key="`attribute_${index}`"
                >
                  <td
                    class="text-no-wrap text-neutral-darken2 pr-4"
                    v-text="attribute.name"
                  />
                  <td class="text-no-wrap">
                    <span v-text="attributeValue(attribute)" /> <span
                      v-if="attribute.unit"
                      class="text--secondary"
                      v-text="attribute.unit"
                    />
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <v-spacer
            v-for="column of requiredSpacerColumns"
            :key="`spacer_column_${column}`"
            class="attribute-column"
          />
        </div>
      </v-card-text>
    </div>
  </v-card>
</template>

<script lang="ts">
import { DataRow, DataType } from '@/components/DataCard/types'
import { defineComponent, PropType } from 'vue'
import { formatValue } from '@/filters/formatting'
import { Project } from '@aedifion.io/aedifion-api'
import { useI18n } from 'vue-i18n'

export default defineComponent({
  name: 'AssetProfileCard',

  props: {
    assignedNumberOfCols: {
      required: true,
      type: Number as PropType<number>,
    },

    loading: {
      default: false,
      required: false,
      type: Boolean as PropType<boolean>,
    },

    project: {
      required: true,
      type: Object as PropType<Project>,
    },
  },

  setup () {
    const { t } = useI18n()
    return { t }
  },

  data () {
    return {
      windowWidth: window.innerWidth as number,
    }
  },

  computed: {
    attributes (): DataRow[] {
      return this.$store.getters['asset_overview/prioritizedProfileData'](this.availableAttributeColumns * 4)
    },

    availableAttributeColumns (): number {
      let result = Math.ceil(this.assignedNumberOfCols / 3) - 1
      if (this.assignedNumberOfCols === 12) {
        if (this.windowWidth < 1450) {
          result--
        }
        if (this.windowWidth < 1200) {
          result--
        }
      } else {
        if (this.windowWidth > 2100) {
          result++
        }
        if (this.windowWidth > 2500) {
          result++
        }
        if (this.windowWidth < 1400) {
          result--
        }
      }
      return result
    },

    avatarUrl (): string|undefined {
      const result: string|undefined = this.project.avatar_url
      return result ?? '/img/dummy-image.jpg'
    },

    requiredAttributeColumns (): number {
      return Math.min(Math.ceil(this.attributes.length / 4), this.availableAttributeColumns)
    },

    requiredSpacerColumns (): number {
      return Math.max(this.availableAttributeColumns - this.requiredAttributeColumns, 0)
    },
  },

  created () {
    window.addEventListener('resize', this.setWindowWidth)
  },

  unmounted () {
    window.removeEventListener('resize', this.setWindowWidth)
  },

  methods: {
    attributesForColumn (column: number): DataRow[] {
      const start = (column - 1) * 4
      const end = column * 4
      return this.attributes.slice(start, end)
    },

    attributeValue (row: DataRow): string {
      let value: string = row.value!
      if (row.type === DataType.Number) {
        value = Number(value).toLocaleString(this.$i18n.locale)
      } else if (row.type === DataType.Year) {
        // The backend appends `.0` to all float type attributes
        if (value.includes('.')) {
          value = value.substring(0, value.indexOf('.'))
        }
      }
      return value
    },

    formatValue,

    setWindowWidth (): void {
      this.windowWidth = window.innerWidth
    },
  },
})
</script>

<style lang="sass" scoped>
table td
  vertical-align: top

.flex-truncate-fix
  min-width: 0
</style>
