import { aedifionApi, ComponentInProjectWithContext } from '@aedifion.io/aedifion-api'
import {
  ComponentsInProjectState, CreateNewComponentPayload, FetchComponentsInProjectPayload, UpdateComponentNamePayload,
} from './types'
import { showErrorNotification, showSuccessNotification } from '@/utils/helpers/notifications'
import { validateNotEqual, validateNotNullish } from '@/utils/helpers/validate'
import { ActionTree } from 'vuex'
import { COMPONENT_LIST_ITEMS_PER_PAGE } from '@/settings'
import i18n from '@/i18n'
import { reportError } from '@/utils/helpers/errors'
import { resetStoreState } from './state'
import { RootState } from '../types'
import texts from '@theme/texts'

export async function fetchBuildingComponent (projectId: number): Promise<ComponentInProjectWithContext|null> {
  const projectBuildingComponentResponse = await aedifionApi.Project.getProjectComponents(projectId, undefined, undefined, 'building', 'alphanumeric_id=B', 'asc', 'nameEN')
  if (!projectBuildingComponentResponse.items || projectBuildingComponentResponse.items?.length === 0) {
    return null
  }

  const componentDef = projectBuildingComponentResponse.items[0]
  const componentDefId = validateNotNullish(componentDef.id)

  return await aedifionApi.Project.getProjectComponent(projectId, componentDefId)
}

export default {
  clear: ({ state }) => {
    resetStoreState(state)
  },

  createComponentInProject: async ({ dispatch, commit, getters, rootGetters }, payload: CreateNewComponentPayload): Promise<boolean | undefined | void> => {
    commit('SET_PENDING_COMPONENT_IN_PROJECT_UPDATE', true)
    const projectId = validateNotNullish(
      rootGetters['projects/currentProjectId'] as number|null,
      { errorMessage: i18n.global.t('notifications.errors.no_project_selected') as string },
    )

    try {
      const response = await aedifionApi.Project.postProjectComponent(projectId, payload.componentID, { nameEN: payload.name })

      showSuccessNotification(`${i18n.global.t('notifications.success.components.create')}`)
      return response.success
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (error: any) {
      let errorMessage: string|null = null
      if (error.status) {
        errorMessage = `${i18n.global.t('notifications.errors.componentInProject.duplicated', { supportEmail: texts.emailSupport })}`
      } else {
        errorMessage = `${i18n.global.t('notifications.errors.components.create', { supportEmail: texts.emailSupport })}`
      }
      showErrorNotification(errorMessage)
      reportError(error)
    } finally {
      commit('SET_PENDING_COMPONENT_IN_PROJECT_UPDATE', false)
      const payload: FetchComponentsInProjectPayload = {
        page: (getters.currentPage as number),
        search: (rootGetters['optimization/search'] as string|null) ?? undefined,
      }
      dispatch('fetchComponentsInProjectWithResults', payload)
    }
  },

  deleteComponentInProject: async ({ commit, rootGetters }, componentInProjectId: number) => {
    commit('SET_LOADING_COMPONENTS_IN_PROJECT', true)
    const projectId = validateNotNullish(
      rootGetters['projects/currentProjectId'] as number|null,
      { errorMessage: i18n.global.t('notifications.errors.no_project_selected') as string },
    )

    try {
      await aedifionApi.Project.deleteProjectComponent(projectId, componentInProjectId)

      showSuccessNotification(`${i18n.global.t('notifications.success.componentInProject.delete')}`)

      commit('REMOVE_COMPONENT_IN_PROJECT_WITH_RESULT', componentInProjectId)
    } catch (error) {
      showErrorNotification(`${i18n.global.t('notifications.errors.componentInProject.delete_component_in_project', { supportEmail: texts.emailSupport })}`)
      reportError(error)
    } finally {
      commit('SET_LOADING_COMPONENTS_IN_PROJECT', false)
    }
  },

  fetchComponentsInProjectWithResults: async ({ commit, rootGetters }, payload: FetchComponentsInProjectPayload) => {
    const projectId = validateNotNullish(
      rootGetters['projects/currentProjectId'] as number|null,
      { errorMessage: i18n.global.t('notifications.errors.no_project_selected') as string },
    )

    commit('SET_LOADING_COMPONENTS_IN_PROJECT', true)
    try {
      const componentsApiResponse = await aedifionApi.Analytics.getAnalysisComponents(projectId, payload.page, COMPONENT_LIST_ITEMS_PER_PAGE, payload.search, payload.filter)
      commit('SET_COMPONENTS_IN_PROJECT_WITH_RESULTS', componentsApiResponse.items)
      commit('SET_PAGINATION', componentsApiResponse.meta)
    } catch (error) {
      showErrorNotification(`${i18n.global.t('notifications.errors.components.fetch', { supportEmail: texts.emailSupport })}`)
      reportError(error)
    } finally {
      commit('SET_LOADING_COMPONENTS_IN_PROJECT', false)
    }
  },

  updateComponentName: async ({ commit, rootGetters }, { componentInProjectID, name }: UpdateComponentNamePayload) => {
    const projectId = validateNotNullish(
      rootGetters['projects/currentProjectId'] as number|null,
      { errorMessage: i18n.global.t('notifications.errors.no_project_selected') as string },
    )

    commit('SET_PENDING_COMPONENT_IN_PROJECT_UPDATE', true)
    try {
      validateNotEqual(name.de, '')
      validateNotEqual(name.en, '')

      let nameToUpdate

      if (name.de && name.en) {
        nameToUpdate = { nameDE: name.de, nameEN: name.en }
      } else if (name.de && !name.en) {
        nameToUpdate = { nameDE: name.de }
      } else if (!name.de && name.en) {
        nameToUpdate = { nameEN: name.en }
      }

      const apiResponse = await aedifionApi.Project.putProjectComponent(projectId, componentInProjectID, nameToUpdate)

      commit('UPDATE_COMPONENT_IN_PROJECT_NAME', apiResponse.resource)
      showSuccessNotification(`${i18n.global.t('notifications.success.componentInProject.update')}`)
    } catch (error) {
      showErrorNotification(`${i18n.global.t('notifications.errors.componentInProject.update', { supportEmail: texts.emailSupport })}`)
      reportError(error)
    } finally {
      commit('SET_PENDING_COMPONENT_IN_PROJECT_UPDATE', false)
    }
  },
} as ActionTree<ComponentsInProjectState, RootState>
