import { acceptHMRUpdate, defineStore } from 'pinia'
import { computed, reactive } from 'vue'
import { EntityLog, EntityLogList, PaginationMeta } from '@aedifion.io/aedifion-api'
import { GetControlsAppLogsPayload, useControlsApiStore } from '@aedifion.io/pinia-aedifion-api-stores'
import { type DateOrRangeString } from '@/utils/types'
import i18n from '@/i18n'
import isEqual from 'lodash/isEqual'
import moment from 'moment'
import { reportError } from '@/utils/helpers/errors'
import { showErrorNotification } from '@/utils/helpers/notifications'
import texts from '@theme/texts'
import { useAIControlsLoggingAppsStore } from './apps'
import { useAppStore } from '@/stores/app'

type State = {
  isFetchingMoreLogs: boolean,
  isReloadingLogs: boolean,
  logs: EntityLog[]|null,
  logsMeta: PaginationMeta|null,
  timePeriod: DateOrRangeString
}

export const useAIControlsLoggingLogsStore = defineStore('AIControlsLoggingLogs', () => {
  const appStore = useAppStore()
  const controlsApiStore = useControlsApiStore()
  const aiControlsLoggingAppsStore = useAIControlsLoggingAppsStore()

  const state: State = reactive({
    isFetchingMoreLogs: false,
    isReloadingLogs: false,
    logs: null,
    logsMeta: null,
    timePeriod: [moment.utc().subtract(1, 'day').format('YYYY-MM-DD'), moment.utc().format('YYYY-MM-DD')],
  })

  // To ensure that the user gets a scrollbar after selecting a controls app and thus can trigger infinite scroll,
  // we have to load a minimum number of logs. 40 seems to work well
  const logsPerPage = 40

  function clear (): void {
    state.isFetchingMoreLogs = false
    state.isReloadingLogs = false
    state.logs = null
    state.logsMeta = null
    state.timePeriod = [moment.utc().subtract(1, 'day').format('YYYY-MM-DD'), moment.utc().format('YYYY-MM-DD')]
  }

  async function resetAndFetchLogs (): Promise<void> {
    state.isReloadingLogs = true
    state.logs = null
    state.logsMeta = null
    await fetchMoreLogsPrivate()
    state.isReloadingLogs = false
  }

  async function setTimePeriod (value: DateOrRangeString): Promise<void> {
    if (isEqual(value, state.timePeriod)) {
      return
    }
    state.timePeriod = value
    await resetAndFetchLogs()
  }

  const canFetchMoreLogs = computed<boolean>(() => {
    if (state.logsMeta) {
      const loadedItems = state.logsMeta.current_page * state.logsMeta.items_per_page
      return state.logsMeta.total_items > loadedItems
    } else {
      return true
    }
  })

  async function fetchMoreLogsPrivate (): Promise<void> {
    if (!aiControlsLoggingAppsStore.state.selectedControlsApp || !canFetchMoreLogs.value) {
      return
    }
    let end, start: Date
    if (state.timePeriod.length === 2) {
      start = new Date(state.timePeriod[0])
      end = moment.utc(state.timePeriod[1]).endOf('day').toDate()
    } else {
      start = moment.utc(state.timePeriod[0]).startOf('day').toDate()
      end = moment.utc(state.timePeriod[0]).endOf('day').toDate()
    }
    try {
      const payload: GetControlsAppLogsPayload = {
        controlsAppId: aiControlsLoggingAppsStore.state.selectedControlsApp,
        end,
        language: i18n.global.locale.value,
        page: (state.logsMeta?.current_page ?? 0) + 1,
        perPage: logsPerPage,
        projectId: appStore.projectId,
        start,
      }
      const response: EntityLogList = await controlsApiStore.getControlsAppLogs(payload, { storeResult: false })
      state.logsMeta = response.meta
      const logItemsWithLocalTime = response.items.map((logItem: EntityLog) => {
        return {
          ...logItem,
          created_at: moment.utc(logItem.created_at).local().toISOString(),
        }
      })
      if (state.logs) {
        state.logs.push(...logItemsWithLocalTime)
      } else {
        state.logs = [...logItemsWithLocalTime]
      }
    } catch (error) {
      const errorMessage = i18n.global.t('notifications.errors.fetch', { resource: i18n.global.t('notifications.resources.controls_app_logs'), supportEmail: texts.emailSupport })
      showErrorNotification(errorMessage)
      reportError(error)
    }
  }

  async function fetchMoreLogs (): Promise<void> {
    if (state.isFetchingMoreLogs) {
      return
    }
    state.isFetchingMoreLogs = true
    if (!state.isReloadingLogs) {
      await fetchMoreLogsPrivate()
    }
    state.isFetchingMoreLogs = false
  }

  return {
    clear,
    fetchMoreLogs,
    resetAndFetchLogs,
    setTimePeriod,
    state,
  }
})

if (import.meta.hot) {
  import.meta.hot.accept(acceptHMRUpdate(useAIControlsLoggingLogsStore, import.meta.hot))
}
