import { clearAssetOverviewStore, updateAssetOverviewQuery, updateAssetOverviewStore } from './assetOverview'
import { clearDataPointsViewStore, updateDataPointsViewQuery, updateDataPointsViewStore } from './dataPointsView'
import { clearOptimizationStore, updateOptimizationQuery, updateOptimizationStore } from './optimization'
import { clearStatusAndAlertsStore, updateStatusAndAlertsQuery, updateStatusAndAlertsStore } from './statusAndAlerts'
import { clearAIControlsLoggingStore, updateAIControlsLoggingStore, updateAIControlsLoggingQuery } from './aiControlsLogging'
import { execRegisteredFunction, projectChanged } from './helpers'
import { NavigationGuard, NavigationGuardNext, RouteLocation, RouteLocationNormalized } from 'vue-router'
import { RootState } from '@/vuex/types'
import { setQueryUpdaterPluginEnabled } from '@/vuex/plugins/QueryUpdater/queryUpdater'
import { Store } from 'vuex'

/**
 * To add a new cockpit or view to this guard, add a new file 'MyCockpit.ts' and implement the worker functions as required.
 * Then register these functions here under the name of the route.
 *
 * 'storeClearers': Functions to clear any query-specific values from the store. Used when the project is changed.
 * 'storeUpdaters': Functions to update any query-specific values in the store. Used when the user enters a URL with a query.
 * 'queryUpdaters': Functions to align the query to the query-specific values in the store. Used when the user navigates to the view.
 *
 * The functions registered here need to have to form 'function (store: Store<RootState>, to: Route): Location|void', although 'to: Route' can be dropped.
 * They should return nothing if the route doesn't have to be changed. Otherwise they should return a Location.
 * Usually, only the Query should be changed in a new location. To create it use the helper function 'createNewNext'.
 */

type QueryGuardFunc = {
  (store: Store<RootState>): RouteLocation|void
}
type QueryGuardFuncWithTarget = {
  (store: Store<RootState>, to: RouteLocationNormalized): RouteLocation|void
}
export type FunctionRegistry = {
  [routePath: string]: QueryGuardFunc|QueryGuardFuncWithTarget
}

const storeClearers: FunctionRegistry = {
  'asset-overview': clearAssetOverviewStore,
  'data-points': clearDataPointsViewStore,
  'optimization-components': clearOptimizationStore,
  'optimization-selected-component-analyses': clearOptimizationStore,
  'status-and-alerts': clearStatusAndAlertsStore,
  'ai-controls-logging': clearAIControlsLoggingStore,
}

const storeUpdaters: FunctionRegistry = {
  'asset-overview': updateAssetOverviewStore,
  'data-points': updateDataPointsViewStore,
  'optimization-components': updateOptimizationStore,
  'optimization-selected-component-analyses': updateOptimizationStore,
  'status-and-alerts': updateStatusAndAlertsStore,
  'ai-controls-logging': updateAIControlsLoggingStore,
}

const queryUpdaters: FunctionRegistry = {
  'asset-overview': updateAssetOverviewQuery,
  'data-points': updateDataPointsViewQuery,
  'optimization-components': updateOptimizationQuery,
  'optimization-selected-component-analyses': updateOptimizationQuery,
  'status-and-alerts': updateStatusAndAlertsQuery,
  'ai-controls-logging': updateAIControlsLoggingQuery,
}

/**
 * The guard checks if the project of the route has changed. If so it calls a function to clear the store.
 * Then it checks if the target route has a query. If so it calls a function to update the store.
 * Otherwise it calls a function to update the query with data from the store.
 * @param store vuex store.
 * @returns the guard.
 */
export const queryGuardMiddleware: (store: Store<RootState>) => NavigationGuard =
  (store: Store<RootState>) => (to: RouteLocation, from: RouteLocation, next: NavigationGuardNext) => {
    setQueryUpdaterPluginEnabled(false)
    let newNext
    if (projectChanged(to, from)) {
      execRegisteredFunction(storeClearers, store, to)
    }
    if (Object.keys(to.query).length > 0 && !('cleared' in to.query)) {
      newNext = execRegisteredFunction(storeUpdaters, store, to)
    } else {
      newNext = execRegisteredFunction(queryUpdaters, store, to)
    }
    // @ts-expect-error //Todo: Check why the type is not correct.
    next(newNext)
    setQueryUpdaterPluginEnabled(true)
  }
