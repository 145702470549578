import { aedifionApi, ComponentAttributeDefinition, ComponentInProjectWithContext } from '@aedifion.io/aedifion-api'
import { AttributesPayload, ComponentAttributesEditorState } from './types'
import { showErrorNotification, showSuccessNotification } from '@/utils/helpers/notifications'
import { ActionTree } from 'vuex'
import i18n from '@/i18n'
import { reportError } from '@/utils/helpers/errors'
import { resetStoreState } from './state'
import { RootState } from '../types'
import texts from '@theme/texts'

export default {
  clear: ({ state }) => {
    resetStoreState(state)
  },

  createComponentAttribute: async ({ commit, rootGetters }, { componentInProjectID, key, value }: AttributesPayload): Promise<void|Error> => {
    commit('SET_LOADING', true)
    const projectId = rootGetters['projects/currentProjectId'] as number|null

    try {
      await aedifionApi.Project.postProjectComponentAttribute({ key, value }, projectId!, componentInProjectID)
      showSuccessNotification(`${i18n.global.t('notifications.success.create', { resource: i18n.global.t('notifications.resources.attribute_definition') })}`)
    } catch (error) {
      showErrorNotification(`${i18n.global.t('notifications.errors.create', { resource: i18n.global.t('notifications.resources.attribute_definition'), supportEmail: texts.emailSupport })}`)
      reportError(error)
      return error as Error
    } finally {
      commit('SET_LOADING', false)
    }
  },

  // @ts-ignore
  deleteComponentAttribute: async ({ commit, rootGetters }, { componentInProjectID, key }: AttributesPayload): Promise<void|Error> => {
    commit('SET_LOADING', true)
    const projectId = rootGetters['projects/currentProjectId'] as number|null

    try {
      await aedifionApi.Project.deleteProjectComponentAttribute(projectId!, componentInProjectID, key)
      showSuccessNotification(`${i18n.global.t('notifications.success.delete', { resource: i18n.global.t('notifications.resources.attribute_definition') })}`)
    } catch (error) {
      showErrorNotification(`${i18n.global.t('notifications.errors.delete', { resource: i18n.global.t('notifications.resources.attribute_definition'), supportEmail: texts.emailSupport })}`)
      reportError(error)
      return error as Error
    } finally {
      commit('SET_LOADING', false)
    }
  },

  fetchComponentInProject: async ({ commit, rootGetters }, componentInProjectID: number) => {
    commit('SET_LOADING', true)
    const projectId = rootGetters['projects/currentProjectId'] as number|null

    try {
      const projectComponentApiResponse: ComponentInProjectWithContext = await aedifionApi.Project.getProjectComponent(projectId!, componentInProjectID)

      commit('SET_COMPONENT_IN_PROJECT', projectComponentApiResponse)
    } catch (error) {
      showErrorNotification(`${i18n.global.t('notifications.errors.fetch', { resource: i18n.global.t('notifications.resources.attribute_definition'), supportEmail: texts.emailSupport })}`)
      reportError(error)
    } finally {
      commit('SET_LOADING', false)
    }
  },

  fetchData: async ({ commit, rootGetters }, componentInProjectID: number) => {
    commit('SET_LOADING_FOR_FETCH', true)
    const projectId = rootGetters['projects/currentProjectId'] as number|null

    try {
      const projectComponentApiResponse: ComponentInProjectWithContext = await aedifionApi.Project.getProjectComponent(projectId!, componentInProjectID)
      const componentID: number|undefined = projectComponentApiResponse?.component?.id
      const attributeDefinitionApiResponse: ComponentAttributeDefinition[] = await aedifionApi.Component.getComponentAttributeDefinitions(componentID!)

      commit('SET_COMPONENT_IN_PROJECT', projectComponentApiResponse)
      commit('SET_COMPONENTS_ATTRIBUTES_DEFINITIONS', attributeDefinitionApiResponse)
    } catch (error) {
      showErrorNotification(`${i18n.global.t('notifications.errors.fetch', { resource: i18n.global.t('notifications.resources.attribute_definition'), supportEmail: texts.emailSupport })}`)
      reportError(error)
    } finally {
      commit('SET_LOADING_FOR_FETCH', false)
    }
  },

  updateComponentAttribute: async ({ commit, rootGetters }, { componentInProjectID, key, value, unit }: AttributesPayload): Promise<void|Error> => {
    commit('SET_LOADING', true)
    const projectId = rootGetters['projects/currentProjectId'] as number|null

    try {
      await aedifionApi.Project.putProjectComponentAttribute(projectId!, componentInProjectID, key, value, unit)
      showSuccessNotification(`${i18n.global.t('notifications.success.update', { resource: i18n.global.t('notifications.resources.attribute_definition') })}`)
    } catch (error) {
      showErrorNotification(`${i18n.global.t('notifications.errors.update', { resource: i18n.global.t('notifications.resources.attribute_definition'), supportEmail: texts.emailSupport })}`)
      reportError(error)
      return error as Error
    } finally {
      commit('SET_LOADING', false)
    }
  },
} as ActionTree<ComponentAttributesEditorState, RootState>
