/* eslint-disable @typescript-eslint/no-explicit-any */
import { aedifionApi, Success, Tag } from '@aedifion.io/aedifion-api'
import { GroupedTagsAndTagKeys, groupTagsByKey } from '@/utils/helpers/tags'
import { ActionTree } from 'vuex'
import i18n from '@/i18n'
import { reportError } from '@/utils/helpers/errors'
import { resetStoreState } from './state'
import { RootState } from '../types'
import { showErrorNotification } from '@/utils/helpers/notifications'
import TagGroupWorker from '@/vuex/tags/TagGroup.worker?worker'
import { TagsState } from './types'
import texts from '@theme/texts'
import { validateNotNullish } from '@/utils/helpers/validate'

export default {
  clear: ({ state }) => {
    resetStoreState(state)
  },

  createTag: async ({ commit, rootGetters }, { payload, dataPointID }): Promise<{
    dataPointID: any;
    tagID: any;
  } | undefined | void> => {
    commit('SET_PENDING_TAG_UPDATE', true)
    const projectId = validateNotNullish(
      rootGetters['projects/currentProjectId'] as number|null,
      { errorMessage: i18n.global.t('notifications.errors.no_project_selected') as string },
    )

    try {
      const projectTagResponse: Success = await aedifionApi.Project.postProjectTag(payload, projectId)
      return {
        dataPointID,
        tagID: projectTagResponse.resource.id,
      }
    } catch (error) {
      showErrorNotification(`${i18n.global.t('notifications.errors.tags.add', { supportEmail: texts.emailSupport })}`)
      reportError(error)
    } finally {
      commit('SET_PENDING_TAG_UPDATE', false)
    }
  },

  fetchTags: async ({ commit, rootGetters }) => {
    commit('SET_LOADING', true)
    const projectId = rootGetters['projects/currentProjectId'] as number|null
    if (projectId === null) {
      showErrorNotification(i18n.global.t('notifications.errors.tags.missing_arguments') as string)
      commit('SET_LOADING', false)
      return
    }

    try {
      const tags: Tag[] = await aedifionApi.Project.getProjectTags(projectId)
      if (window.Worker && tags.length > 0) {
        const tagGroupWorker: Worker = new TagGroupWorker()
        tagGroupWorker.onmessage = (e: MessageEvent<GroupedTagsAndTagKeys>) => {
          const tagGroups = e.data.tagGroups
          const tagKeys = e.data.tagKeys
          commit('SET_TAG_GROUPS', tagGroups)
          commit('SET_TAG_KEYS', tagKeys)
          commit('SET_LOADING', false)
          tagGroupWorker.terminate()
        }
        tagGroupWorker.postMessage(tags)
      } else {
        const { tagGroups, tagKeys } = groupTagsByKey(tags)
        commit('SET_TAG_GROUPS', tagGroups)
        commit('SET_TAG_KEYS', tagKeys)
        commit('SET_LOADING', false)
      }
    } catch (error) {
      showErrorNotification(`${i18n.global.t('notifications.errors.tags.fetch', { supportEmail: texts.emailSupport })}`)
      reportError(error)
      commit('SET_LOADING', false)
    }
  },
} as ActionTree<TagsState, RootState>
