import { PiniaPluginContext } from 'pinia'
import { watch } from 'vue'
import router from '@/router'

export function queryUpdater ({ store }: PiniaPluginContext) {
  watch(() => store.$state.state?.selectedControlsApp, (newValue, oldValue) => {
    if (router.currentRoute?.value?.name === 'ai-controls-logging') {
      if (store.$id === 'AIControlsLoggingApps' && newValue && oldValue !== newValue) {
        router.replace({
          query: { id: newValue },
          replace: true,
        })
      }
    }
  })
}
