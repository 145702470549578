import { ControlsApp, ControlsAppPins } from '@aedifion.io/aedifion-api'
import { GetControlsAppsPayload, useControlsApiStore } from '@aedifion.io/pinia-aedifion-api-stores'
import { ref, watch } from 'vue'
import { useRouter } from 'vue-router'
import { acceptHMRUpdate, defineStore } from 'pinia'
import i18n from '@/i18n'
import { reportError } from '@/utils/helpers/errors'
import { showErrorNotification } from '@/utils/helpers/notifications'
import texts from '@theme/texts'
import { useAIControlsAppPinStore } from '@/stores/views/AIControls/App/pin'
import { useAppStore } from '@/stores/app'

export const useAIControlsAppStore = defineStore('AIControlsApp', () => {
  const router = useRouter()
  const appStore = useAppStore()
  const controlsApiStore = useControlsApiStore()
  const aiControlsAppPinStore = useAIControlsAppPinStore()
  const controlsApp = ref<ControlsApp>()
  const isLoading = ref(false)

  watch(() => appStore.projectId, () => {
    controlsApp.value = undefined
  })

  async function fetchApp (appId: string): Promise<void> {
    if (!appStore.currentProjectControlsAppCount) return

    isLoading.value = true
    try {
      if (!appId) {
        appId = await _fetchRandomAppId() ?? ''
      }

      controlsApp.value = await controlsApiStore.getControlsApp({
        projectId: appStore.projectId,
        controlsAppId: appId,
      })

      await aiControlsAppPinStore.fetchPinValues(_getPinIds(controlsApp.value))
    } catch (error) {
      showErrorNotification(`${i18n.global.t('notifications.errors.fetch', { resource: i18n.global.t('notifications.resources.controls_app'), supportEmail: texts.emailSupport })}`)
      reportError(error)
    } finally {
      isLoading.value = false
    }
  }

  function _getPinIds (controlsApp: ControlsApp): string[] {
    const appPinIds: string[] = []
    const pins = controlsApp.pins
    if (pins) {
      (Object.keys(pins) as Array<keyof ControlsAppPins>).forEach((pinCollection) => {
        pins[pinCollection]!.forEach((pin) => {
          if (pin.dataPointID) {
            appPinIds.push(pin.dataPointID)
          }
        })
      })
    }
    return appPinIds
  }

  async function _fetchRandomAppId (): Promise<string|undefined> {
    if (!appStore.currentProjectControlsAppCount) return
    const payload: GetControlsAppsPayload = {
      page: Math.floor(Math.random() * appStore.currentProjectControlsAppCount) + 1,
      perPage: 1,
      projectId: appStore.projectId,
    }
    const controlsAppsFetchResult = await controlsApiStore.getControlsApps(payload)
    const appId = controlsAppsFetchResult?.items[0]?.id
    router.replace({ path: `/ai-controls/app/${appStore.projectId}/${appId}` })
    return appId
  }

  return {
    controlsApp,
    fetchApp,
    isLoading,
  }
})

if (import.meta.hot) {
  import.meta.hot.accept(acceptHMRUpdate(useAIControlsAppStore, import.meta.hot))
}
