<i18n lang="json" locale="de">
{
  "add_task": "Aufgabe hinzufügen",
  "historic_select_label": "Analysezeitraum",
  "old_result": "Es wird ein Ergebnis für einen älteren Zeitraum angezeigt. Ergebnisse für jüngere Zeiträume sind verfügbar."
}
</i18n>
<i18n lang="json" locale="en">
{
  "add_task": "Add task",
  "historic_select_label": "Analysis period",
  "old_result": "Showing results for older dates. Results for newer analysis dates are available."
}
</i18n>

<template>
  <v-sheet
    class="mb-2 px-2 py-4 fill-height"
    rounded
  >
    <div class="d-flex align-center">
      <v-btn
        data-cy="previousAnalysis"
        :disabled="!hasPreviousAnalysis"
        icon
        rounded
        @click="selectAnalysisResult(false)"
      >
        <v-icon>fa:fal fa-chevron-left</v-icon>
      </v-btn>
      <v-select
        class="mx-2 tw-max-w-fit"
        density="compact"
        hide-details
        :items="analysisResults"
        :label="t('historic_select_label')"
        :loading="loadingResults"
        variant="outlined"
        data-cy="historicalAnalysisResults"
        :model-value="selectedResult"
        @update:model-value="switchResult"
      />
      <v-btn
        data-cy="nextAnalysis"
        :disabled="!hasNextAnalysis"
        icon
        rounded
        @click="selectAnalysisResult"
      >
        <v-icon>fa:fal fa-chevron-right</v-icon>
      </v-btn>
      <v-btn
        v-if="!isReadOnly"
        color="primary-darken2"
        class="ml-auto"
        @click="$emit('add-task')"
      >
        {{ t('add_task') }}
        <v-icon
          size="14"
          class="ml-3"
        >
          fa:far fa-circle-plus
        </v-icon>
      </v-btn>
    </div>
    <span
      v-if="!isLatestResult && !loadingResults"
      class="mt-2 mb-0 mx-11 d-flex justify-start align-items"
      data-cy="newerResultsAvailableWarning"
    >
      <v-icon
        class="mr-2"
        color="warning"
        size="small"
      >fa:fas fa-exclamation-triangle</v-icon>
      <span
        class="text-body-1 text-warning"
        v-text="t('old_result')"
      />
    </span>
  </v-sheet>
</template>

<script lang="ts">
import { AnalysisResult, AnalysisResultSummaries } from '@aedifion.io/aedifion-api'
import { defineComponent } from 'vue'
import { FetchAnalysisResultPayload } from '@/vuex/analysis_instances/types'
import moment from 'moment'
import { reportError } from '@/utils/helpers/errors'
import { useI18n } from 'vue-i18n'

type AnalysisResultSelectItem = {
  title: string;
  value: AnalysisResultSummaries;
}

export default defineComponent({
  name: 'AnalysisHistory',

  props: {
    isReadOnly: {
      type: Boolean,
      default: false,
    },
    latestResultId: {
      required: true,
      validator: prop => typeof prop === 'string' || prop === null,
    },
  },

  emits: ['add-task'],

  setup () {
    const { t } = useI18n()
    return { t }
  },

  computed: {
    analysisResult (): AnalysisResult|null {
      return this.$store.state.analysis_instances.analysisResult
    },

    analysisResultId (): string|null {
      // @ts-expect-error The result_id actually exists, but the type from the API is incorrect
      return this.$store.state.analysis_instances.analysisResult?.result_id ?? null
    },

    analysisResults (): AnalysisResultSelectItem[] {
      return (this.$store.state.analysis_instances.analysisResults ?? [])
        .map((result: AnalysisResultSummaries) => {
          const start: string = moment(result.input_parameters.start).format('YYYY/MM/DD')
          const end: string = moment(result.input_parameters.end).format('YYYY/MM/DD')
          return {
            title: `${start} - ${end}`,
            value: result,
          }
        })
    },

    currentAnalysisListIndex (): number {
      const currentAnalysisResultIndex: number = this.analysisResults.findIndex((analysisResultItem: AnalysisResultSelectItem) => {
        return analysisResultItem.value.result_id === this.selectedResult?.result_id
      })

      return currentAnalysisResultIndex
    },

    hasNextAnalysis (): boolean {
      if (this.analysisResults.length <= 1) return false

      return this.currentAnalysisListIndex - 1 >= 0
    },

    hasPreviousAnalysis (): boolean {
      if (this.analysisResults.length <= 1) return false

      return this.currentAnalysisListIndex + 1 < this.analysisResults.length
    },

    isLatestResult (): boolean {
      return this.selectedResult?.result_id === this.latestResultId
    },

    loadingResults (): boolean {
      return this.$store.state.analysis_instances.loadingAnalysisResults
    },

    selectedResult (): AnalysisResultSummaries|null {
      return this.analysisResults.find((analysisResultItem: AnalysisResultSelectItem) => {
        return analysisResultItem?.value.result_id === this.analysisResultId
      })?.value ?? null
    },
  },

  methods: {
    selectAnalysisResult (nextResult = true): void {
      if (nextResult && this.hasNextAnalysis) {
        this.switchResult(this.analysisResults[this.currentAnalysisListIndex - 1].value)
      } else if (!nextResult && this.hasPreviousAnalysis) {
        this.switchResult(this.analysisResults[this.currentAnalysisListIndex + 1].value)
      }
    },

    async switchResult (newResult: AnalysisResultSummaries): Promise<void> {
      const payload: FetchAnalysisResultPayload = {
        instanceId: this.analysisResult!.instance_id!,
        language: this.$i18n.locale,
        resultId: newResult.result_id!,
      }
      try {
        await this.$store.dispatch('analysis_instances/fetchAnalysisResult', payload)
      } catch (error) {
        reportError(error)
      }
    },
  },
})
</script>
