import type { CompanyWithContextUsers, NewUser } from '@aedifion.io/aedifion-api'
import { computed, ref } from 'vue'
import { PutCompanyUserPayload, useCompanyApiStore, useProjectApiStore } from '@aedifion.io/pinia-aedifion-api-stores'
import { showErrorNotification, showSuccessNotification } from '@/utils/helpers/notifications'
import { defineStore } from 'pinia'
import { type DropDownItem } from '@/components/MultipleDropDown.types'
import i18n from '@/i18n'
import { reportError } from '@/utils/helpers/errors'
import texts from '@theme/texts'
import { useAdministrationStore } from '@/stores/views/Administration/index'

export type UserWithLoadingState = CompanyWithContextUsers & { isLoading: boolean }

export type ProjectRoleId = {
  projectId: number
  roleId: number
}

type CompanyRoleId = number

export type UserCreationPayload = {
  companyRolesIds: CompanyRoleId[],
  projectRoles: ProjectRoleId[],
  user: NewUser
}

export type RolesUpdateDescriptor<T extends CompanyRoleId | ProjectRoleId> = {
  add: T[],
  final: T[]
  remove: T[]
}

export interface UserRolesUpdatePayload {
  companyRoles?: RolesUpdateDescriptor<CompanyRoleId>,
  projectRoles?: RolesUpdateDescriptor<ProjectRoleId>,
  userId: number
}

export const useAdministrationUsersStore = defineStore('administrationUsers', () => {
  const companyApiStore = useCompanyApiStore()
  const projectApiStore = useProjectApiStore()
  const administrationStore = useAdministrationStore()

  const loadingUsers = ref<number[]>([])
  const searchFilter = ref('')
  const companyRolesFilter = ref<number[]>([])
  const projectsFilter = ref<number[]>([])

  function addLoadingUser (id: number): void {
    loadingUsers.value.push(id)
  }

  function removeLoadingUser (id: number): void {
    loadingUsers.value.splice(loadingUsers.value.findIndex((userId) => {
      return userId === id
    }), 1)
  }

  async function createUser (payload: UserCreationPayload): Promise<boolean> {
    let createdUser: CompanyWithContextUsers = {}

    try {
      const createUserResponse = await companyApiStore.postUser({ user: payload.user })
      createdUser = { ...createUserResponse.resource }
    } catch (error) {
      showErrorNotification(i18n.global.t('notifications.errors.create', { resource: i18n.global.t('notifications.resources.user'), supportEmail: texts.emailSupport }) as string)
      reportError(error)
      return false
    }

    showSuccessNotification(i18n.global.t('notifications.success.create', { resource: i18n.global.t('notifications.resources.user') }) as string)

    // CompanyWithContextUsers.id is marked as optional in the TS type but it is actually required in the DB schema
    const companyRolesUpdatePromise = sendCompanyRolesUpdateRequests({ add: payload.companyRolesIds, final: payload.companyRolesIds, remove: [] }, createdUser.id!)
    const projectRolesUpdatePromise = sendProjectRolesUpdateRequests({ add: payload.projectRoles, final: payload.projectRoles, remove: [] }, createdUser.id!)

    const companyRolesUpdateResult = await companyRolesUpdatePromise
    if (companyRolesUpdateResult) {
      createdUser.company_roles = payload.companyRolesIds
    }

    const projectRolesUpdateResult = await projectRolesUpdatePromise
    if (projectRolesUpdateResult) {
      createdUser.project_roles = payload.projectRoles.map((projectRole) => {
        return projectRole.roleId
      })
    }

    administrationStore.pushUser(createdUser)

    return true
  }

  async function deleteUser (userId: number): Promise<void> {
    addLoadingUser(userId)

    try {
      await companyApiStore.deleteUser({ userId })
      administrationStore.removeUser(userId)
      showSuccessNotification(i18n.global.t('notifications.success.delete', { resource: i18n.global.t('notifications.resources.user') }) as string)
    } catch (error) {
      const errorMessage = i18n.global.t('notifications.errors.delete', { resource: i18n.global.t('notifications.resources.user'), supportEmail: texts.emailSupport }) as string
      showErrorNotification(errorMessage)
      reportError(error)
    } finally {
      removeLoadingUser(userId)
    }
  }

  async function updateUser (payload: PutCompanyUserPayload): Promise<void> {
    addLoadingUser(payload.userId)

    try {
      const result = await companyApiStore.putCompanyUser(payload)
      administrationStore.updateUser(result.resource.id, result.resource)
      showSuccessNotification(i18n.global.t('notifications.success.update', { resource: i18n.global.t('notifications.resources.company_profile') }) as string)
    } catch (error) {
      const errorMessage = i18n.global.t('notifications.errors.update', { resource: i18n.global.t('notifications.resources.user'), supportEmail: texts.emailSupport }) as string
      showErrorNotification(errorMessage)
      reportError(error)
    } finally {
      removeLoadingUser(payload.userId)
    }
  }

  function isProjectRoleId (role: CompanyRoleId | ProjectRoleId): role is ProjectRoleId {
    return (role as ProjectRoleId).projectId !== undefined
  }

  function isSameRole <T extends CompanyRoleId | ProjectRoleId> (roleA: T, roleB: T): boolean {
    if (isProjectRoleId(roleA) && isProjectRoleId(roleB)) {
      return roleA.projectId === roleB.projectId && roleA.roleId === roleB.roleId
    } else {
      return roleA === roleB
    }
  }

  function computeRolesUpdatePayload <T extends CompanyRoleId | ProjectRoleId> (oldRoleSet: T[], newRoleSet: T[]): RolesUpdateDescriptor<T> {
    const add = newRoleSet.filter((newRole) => {
      return !oldRoleSet.some((oldRole) => {
        return isSameRole(oldRole, newRole)
      })
    })

    const remove = oldRoleSet.filter((oldRole) => {
      return !newRoleSet.some((newRole) => {
        return isSameRole(oldRole, newRole)
      })
    })

    return {
      add,
      final: newRoleSet,
      remove,
    }
  }

  async function sendCompanyRolesUpdateRequests (companyRolesUpdate: RolesUpdateDescriptor<CompanyRoleId>, userId: number): Promise<boolean> {
    try {
      const companyRolesUpdateRequests: Promise<unknown>[] = []

      companyRolesUpdateRequests.push(...companyRolesUpdate.add.map((roleId) => {
        return companyApiStore.postUserRole({ roleId, userId })
      }))
      companyRolesUpdateRequests.push(...companyRolesUpdate.remove.map((roleId) => {
        return companyApiStore.deleteUserRole({ roleId, userId })
      }))

      await Promise.all(companyRolesUpdateRequests)

      return true
    } catch (error) {
      showErrorNotification(`${i18n.global.t('notifications.errors.user.assignRoles', { supportEmail: texts.emailSupport })}`)
      reportError(error)
      return false
    }
  }

  async function updateCompanyRoles (companyRolesUpdate: RolesUpdateDescriptor<CompanyRoleId>, userId: number, shouldSetUserAsLoading = true): Promise<boolean> {
    if (shouldSetUserAsLoading) {
      addLoadingUser(userId)
    }

    const didRoleUpdateSucceed = await sendCompanyRolesUpdateRequests(companyRolesUpdate, userId)
    if (didRoleUpdateSucceed) {
      administrationStore.updateUser(userId, { company_roles: companyRolesUpdate.final })
    }

    if (shouldSetUserAsLoading) {
      removeLoadingUser(userId)
    }

    return didRoleUpdateSucceed
  }

  async function sendProjectRolesUpdateRequests (projectRolesUpdate: RolesUpdateDescriptor<ProjectRoleId>, userId: number): Promise<boolean> {
    try {
      const projectRolesUpdateRequests: Promise<unknown>[] = []

      projectRolesUpdateRequests.push(...projectRolesUpdate.add.map((projectRoleId) => {
        return projectApiStore.postUserRoleAssignment({ ...projectRoleId, userId })
      }))
      projectRolesUpdateRequests.push(...projectRolesUpdate.remove.map((projectRoleId) => {
        return projectApiStore.deleteUserRoleAssignment({ ...projectRoleId, userId })
      }))

      await Promise.all(projectRolesUpdateRequests)

      return true
    } catch (error) {
      showErrorNotification(`${i18n.global.t('notifications.errors.user.assignRoles', { supportEmail: texts.emailSupport })}`)
      reportError(error)
      return false
    }
  }

  async function updateProjectRoles (projectRolesUpdate: RolesUpdateDescriptor<ProjectRoleId>, userId: number, shouldSetUserAsLoading = true): Promise<boolean> {
    if (shouldSetUserAsLoading) {
      addLoadingUser(userId)
    }

    const didRoleUpdateSucceed = await sendProjectRolesUpdateRequests(projectRolesUpdate, userId)
    if (didRoleUpdateSucceed) {
      administrationStore.updateUser(userId, {
        project_roles: projectRolesUpdate.final.map((projectRoleId) => {
          return projectRoleId.roleId
        }),
      })
    }

    if (shouldSetUserAsLoading) {
      removeLoadingUser(userId)
    }

    return didRoleUpdateSucceed
  }

  async function updateUserRoles (userRolesUpdatePayload: UserRolesUpdatePayload): Promise<boolean> {
    addLoadingUser(userRolesUpdatePayload.userId)

    try {
      const { companyRoles: companyRolesUpdate, projectRoles: projectRolesUpdate, userId } = userRolesUpdatePayload
      const userRolesUpdates: Promise<boolean>[] = []

      if (companyRolesUpdate) {
        userRolesUpdates.push(updateCompanyRoles(companyRolesUpdate, userId, false))
      }
      if (projectRolesUpdate) {
        userRolesUpdates.push(updateProjectRoles(projectRolesUpdate, userId, false))
      }

      return (await Promise.all(userRolesUpdates)).every((hasRequestSucceeded) => { return hasRequestSucceeded })
    } finally {
      removeLoadingUser(userRolesUpdatePayload.userId)
    }
  }

  const adminRoleId = computed(() => {
    return administrationStore.companyRoles.find((companyRole) => {
      return companyRole.name === 'admin'
    })?.id
  })

  function isUserAdmin (userId: number | undefined): boolean {
    if (userId === undefined) {
      return false
    }

    if (adminRoleId.value) {
      return administrationStore.users.find((user) => {
        return user.id === userId
      })?.company_roles?.includes(adminRoleId.value) ?? false
    }

    return false
  }

  function clearFilters (): void {
    searchFilter.value = ''
    companyRolesFilter.value = []
    projectsFilter.value = []
  }

  function extractIdsFromQuery (queryPart: string, validOptions: DropDownItem<number>[]): number[] {
    return queryPart.split(',').map((idFromQuery) => {
      return Number(idFromQuery)
    }).filter((idFromQuery) => {
      return validOptions.some((validOption) => {
        return validOption.id === idFromQuery
      })
    })
  }

  const companyRolesOptions = computed(() => {
    return administrationStore.displayedCompanyRoles.filter((companyRole) => {
      return companyRole.id !== undefined && companyRole.name
    }).map((companyRole) => {
      return {
        id: companyRole.id!,
        label: companyRole.name!,
      }
    })
  })

  const projectsOptions = computed(() => {
    return administrationStore.projects
      .slice()
      .sort((projectA, projectB) => {
        return projectA.name.localeCompare(projectB.name)
      })
      .map((project) => {
        return {
          id: project.id!,
          label: project.name,
        }
      })
  })

  type RouteQuery = {
    projects?: string,
    roles?: string,
    search?: string
  }

  function updateFiltersUsingUrlQuery (routeQuery: RouteQuery): void {
    if (routeQuery.search) {
      searchFilter.value = routeQuery.search
    }
    if (routeQuery.roles) {
      companyRolesFilter.value = extractIdsFromQuery(routeQuery.roles, companyRolesOptions.value)
    }
    if (routeQuery.projects) {
      projectsFilter.value = extractIdsFromQuery(routeQuery.projects, projectsOptions.value)
    }
  }

  function matchesSearchFilter (user: CompanyWithContextUsers): boolean {
    return searchFilter.value
      .split(/\s/)
      .filter((searchWord) => {
        return searchWord !== ''
      })
      .every((searchWord) => {
        searchWord = searchWord.toLowerCase()
        return user.firstName?.toLowerCase().includes(searchWord) ||
          user.lastName?.toLowerCase().includes(searchWord) ||
          user.email?.toLowerCase().includes(searchWord)
      })
  }

  function matchesCompanyRolesFilter (user: CompanyWithContextUsers): boolean {
    return !!user.company_roles?.some((companyRole) => {
      return companyRolesFilter.value.includes(companyRole)
    })
  }

  function matchesProjectsFilter (user: CompanyWithContextUsers): boolean {
    const allowList: number[] = []

    for (const projectId of projectsFilter.value) {
      const project = administrationStore.getProject(projectId)

      if (project) {
        for (const role of project.roles ?? []) {
          if (role.id !== undefined) {
            allowList.push(role.id)
          }
        }
      }
    }

    // admins have access to all projects
    return isUserAdmin(user.id) || !!user.project_roles?.some((projectRole) => {
      return allowList.includes(projectRole)
    })
  }

  const usersGetter = computed<UserWithLoadingState[]>(() => {
    return administrationStore.users.map((user) => {
      return {
        ...user,
        isLoading: user.id !== undefined && loadingUsers.value.includes(user.id),
      }
    })
  })

  const filteredUsers = computed<UserWithLoadingState[]>(() => {
    return usersGetter.value.filter((user) => {
      if (searchFilter.value && !matchesSearchFilter(user)) {
        return false
      }

      if (companyRolesFilter.value.length && !matchesCompanyRolesFilter(user)) {
        return false
      }

      if (projectsFilter.value.length && !matchesProjectsFilter(user)) {
        return false
      }

      return true
    })
  })

  return {
    clearFilters,
    companyRolesFilter,
    companyRolesOptions,
    computeRolesUpdatePayload,
    createUser,
    deleteUser,
    filteredUsers,
    projectsFilter,
    projectsOptions,
    searchFilter,
    updateCompanyRoles,
    updateFiltersUsingUrlQuery,
    updateProjectRoles,
    updateUser,
    updateUserRoles,
  }
})
