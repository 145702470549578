import { aedifionApi, FilesList } from '@aedifion.io/aedifion-api'
import { FileLink, FilesState } from './types'
import { ActionTree } from 'vuex'
import i18n from '@/i18n'
import { reportError } from '@/utils/helpers/errors'
import { resetStoreState } from '@/vuex/files/state'
import { RootState } from '../types'
import { showErrorNotification } from '@/utils/helpers/notifications'
import texts from '@theme/texts'
import { validateNotNullish } from '@/utils/helpers/validate'

export default {
  clear: ({ state }) => {
    resetStoreState(state)
  },

  fetchElevateReports: async ({ commit, rootGetters }): Promise<void> => {
    try {
      const projectId = validateNotNullish(
        rootGetters['projects/currentProjectId'] as number|null,
        { errorMessage: i18n.global.t('notifications.errors.no_project_selected') as string },
      )
      commit('SET_LOADING', true)
      const files: FilesList = await aedifionApi.Project.getProjectFiles(projectId, 'elevate/')
      commit('SET_ELEVATE_REPORTS', files.items)
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (error: any) {
      if (error.status === 403) { // This code is returned when the document database has not been set up for a project or company
        commit('SET_ELEVATE_REPORTS', [])
      } else {
        showErrorNotification(`${i18n.global.t('notifications.errors.fetch', { resource: i18n.global.t('notifications.resources.files'), supportEmail: texts.emailSupport })}`)
        reportError(error)
      }
    } finally {
      commit('SET_LOADING', false)
    }
  },

  fetchFileLink: async ({ rootGetters }, filePath: string): Promise<string|null> => {
    try {
      const projectId = validateNotNullish(
        rootGetters['projects/currentProjectId'] as number|null,
        { errorMessage: i18n.global.t('notifications.errors.no_project_selected') as string },
      )
      const file: FileLink = await aedifionApi.Project.getProjectFile(projectId, filePath, '10m')
      return file.download_link
    } catch (error) {
      showErrorNotification(`${i18n.global.t('notifications.errors.fetch', { resource: i18n.global.t('notifications.resources.files'), supportEmail: texts.emailSupport })}`)
      reportError(error)
      return null
    }
  },
} as ActionTree<FilesState, RootState>
