<i18n locale="de">
{
  "title": "Report",
  "description": "Der Report enthält die wichtigsten Ergebnisse aus dem Projekt. Alle erwähnten Handlungsempfehlungen findest du im Optimization wieder.",
  "download": "Herunterladen"
}
</i18n>
<i18n locale="en">
{
  "title": "Report",
  "description": "The report contains the most important results from the project. You can find all the recommendations mentioned in the Optimization.",
  "download": "Download"
}
</i18n>

<template>
  <v-card class="d-flex flex-column fill-height">
    <v-card-title>
      {{ t('title') }}
    </v-card-title>
    <v-card-text class="text-neutral-darken3 pb-2">
      {{ t('description') }}
    </v-card-text>
    <v-spacer />
    <v-card-actions class="px-4">
      <v-btn
        class="text-primary-darken2"
        variant="elevated"
        color="primary-lighten3"
        :loading="loadingLink"
        @click="downloadReport"
      >
        {{ t('download') }}
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script lang="ts">
import { defineComponent } from 'vue'
import { useI18n } from 'vue-i18n'

export default defineComponent({
  name: 'ElevateReportCard',

  setup () {
    const { t } = useI18n()
    return { t }
  },

  data () {
    return {
      loadingLink: false,
    }
  },

  methods: {
    async downloadReport (): Promise<void> {
      this.loadingLink = true
      const path: string|null = this.$store.getters['files/pathToLatestElevateReport']
      if (path) {
        const url: string|null = await this.$store.dispatch('files/fetchFileLink', path)
        if (url) {
          const link = document.createElement('a')
          link.href = url
          link.target = '_blank'
          link.rel = 'noopener noreferrer'
          link.click()
        }
      }
      this.loadingLink = false
    },
  },
})
</script>
