import { acceptHMRUpdate, defineStore } from 'pinia'
import { computed, ref, watch } from 'vue'
import { GetProjectUsersPayload, useProjectApiStore } from '@aedifion.io/pinia-aedifion-api-stores'
import { PaginationMeta, ShortUser } from '@aedifion.io/aedifion-api'
import i18n from '@/i18n'
import { reportError } from '@/utils/helpers/errors'
import { showErrorNotification } from '@/utils/helpers/notifications'
import texts from '@theme/texts'
import { useAppStore } from '@/stores/app'
import debounce from 'lodash.debounce'

export const useUsersInProjectStore = defineStore('usersInProject', () => {
  const projectApiStore = useProjectApiStore()
  const appStore = useAppStore()
  const projectUsers = ref<ShortUser[]|null>(null)
  const projectUsersMeta = ref<PaginationMeta|null>(null)
  const loadingComponentUsers = ref(false)
  const search = ref('')

  const canFetchMoreProjectUsers = computed(() => {
    if (projectUsers.value && projectUsersMeta.value) {
      return projectUsers.value.length < projectUsersMeta.value.total_items
    }

    return true
  })

  async function fetchMoreProjectUsers (): Promise<void> {
    if (!canFetchMoreProjectUsers.value || loadingComponentUsers.value) {
      return
    }

    loadingComponentUsers.value = true

    try {
      const payload: GetProjectUsersPayload = {
        page: (projectUsersMeta.value?.current_page ?? 0) + 1,
        perPage: 20,
        projectId: appStore.projectId,
        search: search.value,
      }

      const componentsApiResponse = await projectApiStore.getProjectUsers(payload)

      if (projectUsers.value === null) {
        projectUsers.value = componentsApiResponse.items!
      } else {
        projectUsers.value.push(...componentsApiResponse.items!)
      }
      projectUsersMeta.value = componentsApiResponse.meta!
    } catch (error) {
      showErrorNotification(`${i18n.global.t('notifications.errors.users.fetch', { supportEmail: texts.emailSupport })}`)
      reportError(error)
    } finally {
      loadingComponentUsers.value = false
    }
  }

  function clearStore () {
    projectUsers.value = []
    projectUsersMeta.value = null
  }

  watch(() => appStore.projectId, () => {
    clearStore()
  })

  async function searchUsersInProjectWithResults () {
    clearStore()

    await fetchMoreProjectUsers()
  }

  const debouncedSearchUsersInProjectWithResults = debounce(searchUsersInProjectWithResults, 500)

  watch(() => search.value, () => {
    debouncedSearchUsersInProjectWithResults()
  })

  return {
    fetchMoreProjectUsers,
    loadingComponentUsers,
    projectUsers,
    projectUsersMeta,
    search,
  }
})

if (import.meta.hot) {
  import.meta.hot.accept(acceptHMRUpdate(useUsersInProjectStore, import.meta.hot))
}
