
import { ControlsAppStatus, GetControlsAppsPayload, useControlsApiStore } from '@aedifion.io/pinia-aedifion-api-stores'
import { ControlsAppList } from '@aedifion.io/aedifion-api'
import { defineStore } from 'pinia'
import i18n from '@/i18n'
import { ref } from 'vue'
import { reportError } from '@/utils/helpers/errors'
import { showErrorNotification } from '@/utils/helpers/notifications'
import texts from '@theme/texts'
import { useAppStore } from '@/stores/app'

interface AppsActivityCounts {
  active: number
  inactive: number
}

interface AlgorithmActivityCounts {
  algorithm: string
  appsActivityCounts: AppsActivityCounts
  sampleAppId?: string
}

const APP_ACTIVITY_STATES: Record<keyof AppsActivityCounts, ControlsAppStatus[]> = {
  active: [ControlsAppStatus.RUNNING, ControlsAppStatus.STOP_INITIATED, ControlsAppStatus.STOP_FAILED],
  inactive: [ControlsAppStatus.READY, ControlsAppStatus.RUN_INITIATED, ControlsAppStatus.STOPPED, ControlsAppStatus.DIED, ControlsAppStatus.RUN_FAILED],
}

export function isActiveAppStatus (status: ControlsAppStatus): boolean {
  return APP_ACTIVITY_STATES.active.includes(status)
}

export const useAlgorithmsStore = defineStore('AIControlsAlgorithms', () => {
  const appStore = useAppStore()
  const controlsApiStore = useControlsApiStore()

  const algorithms = ref<Array<AlgorithmActivityCounts>>([])
  const isLoading = ref(false)

  function clearAlgorithms (): void {
    algorithms.value = []
  }

  async function fetchAllAlgorithmsActivities (): Promise<void> {
    isLoading.value = true

    // clear state
    clearAlgorithms()

    // collect all available algorithms
    const allAlgorithms = new Set<string>()
    try {
      const components = await controlsApiStore.getControlsAlgorithmsByComponent({})
      Object.values(components).forEach((component) => {
        Object.keys(component as Record<string, unknown>).forEach((algorithm) => allAlgorithms.add(algorithm))
      })

      // fetch app activities for each algorithm in project
      const algorithmAppsRequests: Promise<AlgorithmActivityCounts>[] = []
      allAlgorithms.forEach(async (algorithm) => {
        algorithmAppsRequests.push(_fetchAlgorithmActivities(algorithm))
      })
      const appsActivityCounts = await Promise.all(algorithmAppsRequests)
      appsActivityCounts.forEach((response) => algorithms.value.push(response))

      // sort algorithms by active apps count descending (when same than inactive apps count descending)
      algorithms.value.sort((a, b) => {
        if (a.appsActivityCounts.active > b.appsActivityCounts.active) {
          return -1
        } else if (a.appsActivityCounts.active < b.appsActivityCounts.active) {
          return 1
        } else if (a.appsActivityCounts.inactive > b.appsActivityCounts.inactive) {
          return -1
        } else if (a.appsActivityCounts.inactive < b.appsActivityCounts.inactive) {
          return 1
        } else {
          return 0
        }
      })
    } catch (error) {
      reportError(error)
      showErrorNotification(i18n.global.t('notifications.errors.fetch', { resource: i18n.global.t('notifications.resources.controls_apps_algorithms'), supportEmail: texts.emailSupport }))
    } finally {
      isLoading.value = false
    }
  }

  async function _fetchAlgorithmActivities (algorithm: string): Promise<AlgorithmActivityCounts> {
    const controlsAppsRequests: Promise<ControlsAppList>[] = [];
    (Object.keys(APP_ACTIVITY_STATES) as Array<keyof AppsActivityCounts>).forEach((state) => {
      const stateFilter = APP_ACTIVITY_STATES[state].map((status) => `status_code=${status}`).join(';')
      const payload: GetControlsAppsPayload = {
        filter: `algorithm=${algorithm};${stateFilter}`,
        projectId: appStore.projectId,
        page: 1,
        perPage: 1,
      }
      controlsAppsRequests.push(controlsApiStore.getControlsApps(payload))
    })
    try {
      const responses = await Promise.all(controlsAppsRequests)
      return { algorithm, appsActivityCounts: { active: responses[0].meta.total_items, inactive: responses[1].meta.total_items }, sampleAppId: responses[0].items[0]?.id || responses[1].items[0]?.id }
    } catch (error) {
      return { algorithm, appsActivityCounts: { active: 0, inactive: 0 } }
    }
  }

  return {
    algorithms,
    clearAlgorithms,
    fetchAllAlgorithmsActivities,
    isLoading,
  }
})
