import { ActionTree } from 'vuex'
import { aedifionApi } from '@aedifion.io/aedifion-api'
import { ComponentsState } from './types'
import i18n from '@/i18n'
import { reportError } from '@/utils/helpers/errors'
import { RootState } from '../types'
import { showErrorNotification } from '@/utils/helpers/notifications'
import texts from '@theme/texts'

export default {
  fetchComponents: async ({ commit }) => {
    commit('SET_LOADING_COMPONENTS', true)
    try {
      const response = await aedifionApi.Component.getComponents()
      commit('SET_COMPONENTS', response)
    } catch (error) {
      showErrorNotification(`${i18n.global.t('notifications.errors.components.fetch', { supportEmail: texts.emailSupport })}`)
      reportError(error)
    } finally {
      commit('SET_LOADING_COMPONENTS', false)
    }
  },
} as ActionTree<ComponentsState, RootState>
