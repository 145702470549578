import * as Sentry from '@sentry/vue'
import { useStore as baseUseStore, createStore, Store, StoreOptions } from 'vuex'
import { aedifionApi } from '@aedifion.io/aedifion-api'
import AIControlsModule from '@/vuex/ai_controls'
import { Alerts } from '@/services/alerta'
import AlertsModule from '@/vuex/alerts'
import AnalysisFunctionsModule from '@/vuex/analysis_functions'
import AnalysisInstancseModule from '@/vuex/analysis_instances'
import AnalysisOverviewModule from '@/vuex/analysis_overview'
import AssetOverviewModule from '@/vuex/asset_overview'
import AssetProfileModule from '@/vuex/asset_profile'
import BuildingAnalysesModule from '@/vuex/building_analyses'
import ComponentAttributesEditorModule from '@/vuex/component_attributes_editor'
import Components from '@/vuex/components'
import ComponentsInProjectModule from '@/vuex/components_in_project'
import DatapointsModule from '@/vuex/datapoints'
import DataPointsView from '@/vuex/data_points_view'
import EndpointsModule from '@/vuex/endpoints'
import FilesModule from '@/vuex/files'
import { initialApiRequestsCompleted } from '@/utils/helpers/hooks'
import LabelsModule from '@/vuex/labels'
import MappingEditorModule from '@/vuex/mapping_editor'
import { oidcSettings } from '@/utils/oidc'
import OptimizationModule from '@/vuex/optimization'
import ProjectsModule from '@/vuex/projects'
import { RootState } from './types'
import StatusAndAlertsModule from '@/vuex/status_and_alerts'
import TagsModule from '@/vuex/tags'
import { useAssetOverviewStore } from '@/stores/views/AssetOverview'
import { useMeterStore } from '@/stores/views/Meter'
import { User } from 'oidc-client-ts'
import { useUserStore } from '@/stores/user'
import { vuexOidcCreateStoreModule } from 'vuex-oidc'

let isRequiredDataAlreadyFetched = false

function shouldFetchRequiredData () {
  // it’s not great that we change the behavior for testing specifically,
  // but it’s not really possible to mock this without putting it in a separate
  // module (= file) 🤷🏻‍♂️
  return import.meta.env.MODE === 'test-unit' || !isRequiredDataAlreadyFetched
}

async function fetchRequiredData (): Promise<void> {
  if (shouldFetchRequiredData()) {
    const initialApiRequestsPromises: Promise<unknown>[] = []

    const userStore = useUserStore()

    initialApiRequestsPromises.push(userStore.fetchUser())
    initialApiRequestsPromises.push(store.dispatch('endpoints/fetchEndpoints'))
    initialApiRequestsPromises.push(store.dispatch('projects/fetchProjects'))
    initialApiRequestsPromises.push(store.dispatch('labels/fetchLabelSystems'))
    await Promise.all(initialApiRequestsPromises)
    // If the user has only one project then several actions send them to the Asset Overview if the nudge isn't shown, otherwise the Optimization.
    // To decide which we need the B+NFA attribute from the building component.
    if (store.state.projects.projects.length === 1) {
      await store.dispatch('building_analyses/fetchBuildingComponentForProject', store.state.projects.projects[0].project!.id)
    }
    isRequiredDataAlreadyFetched = true
  }
}

export const initializeApiRequests = async (user: User): Promise<void> => {
  if (user?.profile?.aed_id === undefined) {
    Sentry.captureMessage('Authentication: user.profile.aed_id is undefined.', 'warning')
  } else {
    Sentry.setUser({ id: String(user!.profile!.aed_id! as number) })
  }

  const accessToken = user.access_token
  aedifionApi.initServices(window.configuration.AEDIFION_API_URL, accessToken)
  Alerts.setAccessToken(accessToken)

  fetchRequiredData().then(() => {
    initialApiRequestsCompleted()
  })
}

function redirectToLogin () {
  store.dispatch('auth/authenticateOidc')
}

const store: Store<RootState> = createStore({
  actions: {
    clearProjectDependentStores ({ dispatch }) {
      const meterStore = useMeterStore()
      meterStore.clearMeterStore()

      const assetOverviewStore = useAssetOverviewStore()
      assetOverviewStore.clearStore()

      dispatch('analysis_functions/clear', undefined, { root: true })
      dispatch('analysis_instances/clear', undefined, { root: true })
      dispatch('analysis_overview/clear', undefined, { root: true })
      dispatch('asset_overview/clear', undefined, { root: true })
      dispatch('asset_profile/clear', undefined, { root: true })
      dispatch('components_in_project/clear', undefined, { root: true })
      dispatch('ai_controls/clear', undefined, { root: true })
      dispatch('data_points_view/clear', undefined, { root: true })
      dispatch('datapoints/clear', undefined, { root: true })
      dispatch('status_and_alerts/clear', undefined, { root: true })
      dispatch('files/clear', undefined, { root: true })
      dispatch('mapping_editor/clear', undefined, { root: true })
      dispatch('optimization/clear', undefined, { root: true })
      dispatch('tags/clear', undefined, { root: true })
    },
  },
  modules: {
    ai_controls: AIControlsModule,
    alerts: AlertsModule,
    analysis_functions: AnalysisFunctionsModule,
    analysis_instances: AnalysisInstancseModule,
    analysis_overview: AnalysisOverviewModule,
    asset_overview: AssetOverviewModule,
    asset_profile: AssetProfileModule,
    auth: vuexOidcCreateStoreModule(
      oidcSettings,
      {
        isAuthenticatedBy: 'access_token',
        namespaced: true,
        publicRoutePaths: ['/auth-failed'],
      },
      {
        automaticSilentRenewError: redirectToLogin,
        silentRenewError: redirectToLogin,
        userLoaded: initializeApiRequests,
      },
    ),
    building_analyses: BuildingAnalysesModule,
    component_attributes_editor: ComponentAttributesEditorModule,
    components: Components,
    components_in_project: ComponentsInProjectModule,
    data_points_view: DataPointsView,
    datapoints: DatapointsModule,
    endpoints: EndpointsModule,
    files: FilesModule,
    labels: LabelsModule,
    mapping_editor: MappingEditorModule,
    optimization: OptimizationModule,
    projects: ProjectsModule,
    status_and_alerts: StatusAndAlertsModule,
    tags: TagsModule,
  },
} as StoreOptions<RootState>)

export function useStore (): Store<RootState> {
  return baseUseStore()
}

export default store
