<script setup lang="ts">
import { computed, onMounted } from 'vue'
import { NavigationGuardNext, onBeforeRouteUpdate, RouteLocationNormalized, useRouter } from 'vue-router'
import CockpitNudge from '@/components/CockpitNudge.vue'
import ControlsAlgorithmCard from '@/views/AIControls/ControlsAlgorithmCard.vue'
import ControlsAppSelector from '@/views/AIControls/ControlsAppSelector.vue'
import MasonryGrid from '@/components/MasonryGrid.vue'
import PageHeader from '@/components/PageHeader.vue'
import StatusControlsButton from './StatusControlsButton.vue'
import { storeToRefs } from 'pinia'
import { useAlgorithmsStore } from '@/stores/views/AIControls/algorithms'
import { useI18n } from 'vue-i18n'
import useIsReadonly from '@/composables/useIsReadOnly'
import { useStore } from 'vuex'
import { useAppStore } from '@/stores/app'
import { useSelectControlsApp } from './Composables/useSelectControlsApp'

const { t } = useI18n()
const { isReadOnly } = useIsReadonly()
const algorithmsStore = useAlgorithmsStore()
const { algorithms, isLoading } = storeToRefs(algorithmsStore)
const store = useStore()
const router = useRouter()

onMounted(async () => {
  await fetchAlgorithmsAndAppsCount()
})

onBeforeRouteUpdate(async (to: RouteLocationNormalized, from: RouteLocationNormalized, next: NavigationGuardNext) => {
  if (to.params.project && from.params.project && to.params.project !== from.params.project) {
    await fetchAlgorithmsAndAppsCount()
  }
  next()
})

async function fetchAlgorithmsAndAppsCount () {
  algorithmsStore.clearAlgorithms()
  await store.dispatch('ai_controls/fetchAndSetControlsAppsStatus')
  await useAlgorithmsStore().fetchAllAlgorithmsActivities()
}

const controlsStatus = computed(() => {
  return store.state.ai_controls.controlsStatus
})

const showCockpitNudge = computed(() => {
  return store.state.ai_controls.showCockpitNudge
})

// #region CONTROL APPS SELECTOR
const { debouncedSearchApps, fetchMoreControlsApps, selectControlsApp, apps, search } = useSelectControlsApp()
const appStore = useAppStore()
// #endregion

const navigateToAppDetails = (sampleAppId: string) => {
  return router.resolve({
    name: 'ai-controls-app-id',
    params: {
      project: appStore.projectId,
      appId: sampleAppId,
    },
  })
}
</script>

<template>
  <div
    class="layout-wrapper"
    :style="[showCockpitNudge ? {'overflow': 'hidden'} : {}]"
  >
    <div :class="{'cockpitNudgeBlur': showCockpitNudge}">
      <PageHeader
        title-key="links.meta.title.ai_controls"
      >
        <ControlsAppSelector
          v-model:search="search"
          class="tw-ml-auto"
          :selected-controls-app="null"
          :show-apps-state="false"
          :select-first-item="false"
          :control-apps="apps"
          :project-id="appStore.projectId"
          @fetch-more-controls-apps="fetchMoreControlsApps"
          @search-controls-apps="debouncedSearchApps"
          @select-controls-app="selectControlsApp"
        >
          <template #item-prepend="{ item }">
            <v-icon
              start
              size="small"
              :color="item.raw.custom?.icon.color"
            >
              {{ item.raw.custom?.icon.name }}
            </v-icon>
          </template>
          <template #item-action="{ item }">
            <v-btn
              variant="text"
              class="tw-ml-auto tw-p-0 tw-uppercase text-neutral-darken1"
            >
              <span class="tw-mr-4">{{ item.raw.custom?.algorithm }}</span>
              <v-icon color="neutral-darken4">
                fa:fal fa-arrow-right-long
              </v-icon>
            </v-btn>
          </template>
        </ControlsAppSelector>
        <StatusControlsButton
          v-if="!isReadOnly"
          class="tw-ml-2"
          :status-code="controlsStatus"
        />
      </PageHeader>
      <div
        v-if="isLoading"
        class="tw-grid tw-gap-6 tw-grid-cols-1 md:tw-grid-cols-2 lg:tw-grid-cols-3 xl:grid-cols-4"
      >
        <ControlsAlgorithmCard
          v-for="index in 9"
          :key="index"
        />
      </div>
      <MasonryGrid v-else>
        <ControlsAlgorithmCard
          v-for="(algorithm, index) of algorithms"
          :key="index"
          :algorithm="algorithm.algorithm"
          truncate-description
          :index="index"
          :active-apps="algorithm.appsActivityCounts.active"
          :inactive-apps="algorithm.appsActivityCounts.inactive"
          :to="algorithm.sampleAppId ? navigateToAppDetails(algorithm.sampleAppId) : undefined"
        />
      </MasonryGrid>
    </div>
    <CockpitNudge
      :name="t('links.meta.title.ai_controls')"
      :title="t('nudge_title')"
      :value="showCockpitNudge"
    />
  </div>
</template>

<style lang="sass" scoped>
.layout-wrapper
  font-feature-settings: 'cv01' on, 'ss01' on, 'ss04' on
.cockpitNudgeBlur
  -webkit-filter: blur(2px)
  -moz-filter: blur(2px)
  -o-filter: blur(2px)
  -ms-filter: blur(2px)
  filter: blur(2px)
</style>

<i18n locale="de">
{
  "nudge_title": "Wir übernehmen die automatische Kontrolle des Systems!"
}
</i18n>

<i18n locale="en">
{
  "nudge_title": "We take over automatic control of the system!"
}
</i18n>
