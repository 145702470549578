import { DataPointWithContext } from '@aedifion.io/aedifion-api'

export type ControlsDataPoint = {
  dataPoint: DataPointWithContext;
  latestValue: number|null;
  unitLabelId: string|null;
}

export type AIControlsState = {
  controlsStatus: number | null;
  dataPoints: ControlsDataPoint[] | null;
  loadingControlsStatus: boolean;
  loadingDataPoints: boolean;
  showCockpitNudge: boolean;
}

export enum ControlsAppStatus {
  Starting = 200,
  Running = 202,
  ReadyToStart = 102,
  NoControlsAppsFound = 404,
}

export enum ControlsAppStoppedStatus {
  Stopping = 300,
  Stopped = 302,
  StoppedDueToTechnicalMaintenance = 303,
  StoppedDueToConnectionLossOfTheEdgeDevice = 307
}
