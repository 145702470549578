<script setup lang="ts">
import { computed, ref } from 'vue'
import moment from 'moment'
import StyledDatepicker from './StyledDatepicker.vue'
import { useI18n } from 'vue-i18n'

type Props = {
  value?: [start: string, end: string] | null
}

const props = withDefaults(defineProps<Props>(), {
  value: null,
})

const emit = defineEmits<{
  (e: 'datepicker:change', value: [start: string, end: string]): void
}>()

const { t } = useI18n()

const datepicker = ref<InstanceType<typeof StyledDatepicker> | null>(null)

// #region date/string range conversion
function convertDateRangeToString (dateRange: [start: Date, end: Date]): [start: string, end: string] {
  return dateRange.map((date) => moment(date).format('YYYY-MM-DD')) as [start: string, end: string]
}

function convertStringRangeToDate (stringRange: [start: string, end: string]): [start: Date, end: Date] {
  return stringRange.map((date) => moment(date).toDate()) as [start: Date, end: Date]
}

// #endregion

function emitDatepickerChangeFromDate (dateRange: [startDate: Date, endDate: Date]) {
  const stringRange = convertDateRangeToString(dateRange)
  if (stringRange[0] !== props.value?.[0] || stringRange[1] !== props.value?.[1]) {
    emit('datepicker:change', stringRange)
  }
}

// #region Predefined range buttons
const lastWeekRange = computed(() => [
  moment.utc().subtract(7, 'd').format('YYYY-MM-DD'),
  moment.utc().format('YYYY-MM-DD'),
] as [start: string, end: string])

const isRangeLastWeek = computed(() => (dateRange: [start: Date, end: Date]) => {
  const stringRange = convertDateRangeToString(dateRange)
  return stringRange[0] === lastWeekRange.value[0] && stringRange[1] === lastWeekRange.value[1]
})

function setRangeToLastWeek () {
  datepicker.value?.vueDatePicker?.updateInternalModelValue(convertStringRangeToDate(lastWeekRange.value))
  datepicker.value?.vueDatePicker?.selectDate()
  datepicker.value?.vueDatePicker?.closeMenu()
}

const lastMonthRange = computed(() => [
  moment.utc().subtract(1, 'M').format('YYYY-MM-DD'),
  moment.utc().format('YYYY-MM-DD'),
] as [start: string, end: string])

const isRangeLastMonth = computed(() => (dateRange: [start: Date, end: Date]) => {
  const stringRange = convertDateRangeToString(dateRange)
  return stringRange[0] === lastMonthRange.value[0] && stringRange[1] === lastMonthRange.value[1]
})

function setRangeToLastMonth () {
  datepicker.value?.vueDatePicker?.updateInternalModelValue(convertStringRangeToDate(lastMonthRange.value))
  datepicker.value?.vueDatePicker?.selectDate()
  datepicker.value?.vueDatePicker?.closeMenu()
}

const lastSixMonthsRange = computed(() => [
  moment.utc().subtract(6, 'M').format('YYYY-MM-DD'),
  moment.utc().format('YYYY-MM-DD'),
] as [start: string, end: string])

const isRangeLastSixMonths = computed(() => (dateRange: [start: Date, end: Date]) => {
  const stringRange = convertDateRangeToString(dateRange)
  return stringRange[0] === lastSixMonthsRange.value[0] && stringRange[1] === lastSixMonthsRange.value[1]
})

function setRangeToLastSixMonths () {
  datepicker.value?.vueDatePicker?.updateInternalModelValue(convertStringRangeToDate(lastSixMonthsRange.value))
  datepicker.value?.vueDatePicker?.selectDate()
  datepicker.value?.vueDatePicker?.closeMenu()
}

const lastYearRange = computed(() => [
  moment.utc().subtract(1, 'y').format('YYYY-MM-DD'),
  moment.utc().format('YYYY-MM-DD'),
] as [start: string, end: string])

const isRangeLastYear = computed(() => (dateRange: [start: Date, end: Date]) => {
  const stringRange = convertDateRangeToString(dateRange)
  return stringRange[0] === lastYearRange.value[0] && stringRange[1] === lastYearRange.value[1]
})

function setRangeToLastYear () {
  datepicker.value?.vueDatePicker?.updateInternalModelValue(convertStringRangeToDate(lastYearRange.value))
  datepicker.value?.vueDatePicker?.selectDate()
  datepicker.value?.vueDatePicker?.closeMenu()
}

const rangeButtons = [{
  id: 'last-week',
  label: t('last_week'),
  isRange: isRangeLastWeek.value,
  setRange: setRangeToLastWeek,
}, {
  id: 'last-month',
  label: t('last_month'),
  isRange: isRangeLastMonth.value,
  setRange: setRangeToLastMonth,
}, {
  id: 'last-six-months',
  label: t('last_six_months'),
  isRange: isRangeLastSixMonths.value,
  setRange: setRangeToLastSixMonths,
}, {
  id: 'last-year',
  label: t('last_year'),
  isRange: isRangeLastYear.value,
  setRange: setRangeToLastYear,
}]

// #endregion
</script>

<template>
  <StyledDatepicker
    ref="datepicker"
    :enable-time-picker="false"
    :format="t('date_format')"
    :label="t('dates.time_period')"
    :max-date="new Date()"
    :model-value="value"
    :multi-calendars="{ solo: true }"
    :range="{ partialRange: false }"
    @update:model-value="emitDatepickerChangeFromDate"
  >
    <template #action-extra="{ internalModelValue }">
      <div class="tw-flex tw-gap-1 tw-px-6 tw-pb-4">
        <v-chip
          v-for="rangeButton of rangeButtons"
          :key="rangeButton.id"
          :color="rangeButton.isRange(internalModelValue) ? 'primary-darken2' : 'neutral-darken1'"
          :data-testid="`${rangeButton.id}-button`"
          :prepend-icon="rangeButton.isRange(internalModelValue) ? 'fal fa-check' : undefined"
          variant="outlined"
          @click="rangeButton.setRange"
        >
          {{ rangeButton.label }}
        </v-chip>
      </div>
    </template>
  </StyledDatepicker>
</template>

<i18n locale="de">
{
  "date_format": "dd.MM.yyyy",
  "last_week": "Letzte Woche",
  "last_month": "Letzter Monat",
  "last_six_months": "Letzte 6 Monate",
  "last_year": "Letztes Jahr"
}
</i18n>
<i18n locale="en">
{
  "date_format": "MM/dd/yyyy",
  "last_week": "Last week",
  "last_month": "Last month",
  "last_six_months": "Last 6 months",
  "last_year": "Last year"
}
</i18n>
