<template>
  <div class="full-height">
    <AnalysisResults />
    <TrendView ref="trendView" />
  </div>
</template>

<script lang="ts">
import { NavigationGuardNext, RouteLocationNormalized } from 'vue-router'
import AnalysisResults from './AnalysisResults.vue'
import { defineComponent } from 'vue'
import TrendView from './TrendView.vue'

export default defineComponent({
  name: 'ComponentOperations',

  components: {
    AnalysisResults,
    TrendView,
  },

  async beforeRouteUpdate (to: RouteLocationNormalized, from: RouteLocationNormalized, next: NavigationGuardNext) {
    if (
      (to.params.project && from.params.project && parseInt(to.params.project as string) !== parseInt(from.params.project as string)) ||
      (to.params.componentInProjectId && parseInt(to.params.componentInProjectId as string) !== parseInt(from.params.componentInProjectId as string))
    ) {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      (this.$refs.trendView as any).resetSamplerate()
    }
    next()
  },
})
</script>
