import { acceptHMRUpdate, defineStore } from 'pinia'
import { computed, reactive, ref } from 'vue'
import { ControlsApp, ControlsAppShort, PaginationMeta } from '@aedifion.io/aedifion-api'
import { ControlsAppStatus, GetControlsAppPayload, GetControlsAppsPayload, PostControlsAppRunPayload, useControlsApiStore } from '@aedifion.io/pinia-aedifion-api-stores'
import i18n from '@/i18n'
import { reportError } from '@/utils/helpers/errors'
import { showErrorNotification } from '@/utils/helpers/notifications'
import texts from '@theme/texts'
import { useAppStore } from '@/stores/app'

type State = {
  apps: ControlsAppShort[]|null,
  appsMeta: PaginationMeta|null,
  loading: boolean,
  search: string,
  selectedControlsApp: string|null,
}

export const useAIControlsLoggingAppsStore = defineStore('AIControlsLoggingApps', () => {
  const appStore = useAppStore()
  const controlsApiStore = useControlsApiStore()

  const transitioningAppsPollingTimeout = 10000
  const transitioningAppsPollingTimeoutId = ref<number | null>(null)
  const transitioningApps = ref<string[]>([])

  const state: State = reactive({
    apps: null,
    appsMeta: null,
    loading: false,
    search: '',
    selectedControlsApp: null,
  })

  function clear (): void {
    state.apps = null
    state.appsMeta = null
    state.search = ''
    state.selectedControlsApp = null
  }

  async function searchControlsApps (value: string): Promise<void> {
    state.search = value
    state.apps = null
    state.appsMeta = null
    await fetchMoreControlsApps()
    detectTransitioningAppsAndPoll()
  }

  const canFetchMoreControlsApps = computed<boolean>(() => {
    if (state.appsMeta) {
      const loadedItems = state.appsMeta.current_page * state.appsMeta.items_per_page
      return state.appsMeta.total_items > loadedItems
    } else {
      return true
    }
  })

  async function fetchMoreControlsApps (): Promise<void> {
    if (!canFetchMoreControlsApps.value || state.loading) {
      return
    }

    state.loading = true

    try {
      const payload: GetControlsAppsPayload = {
        page: (state.appsMeta?.current_page ?? 0) + 1,
        perPage: 40,
        projectId: appStore.projectId,
        search: state.search || undefined,
      }
      const result = await controlsApiStore.getControlsApps(payload, { storeResult: false })

      state.appsMeta = result.meta
      if (state.apps) {
        state.apps.push(...result.items)
      } else {
        state.apps = [...result.items]
      }
    } catch (error) {
      const errorMessage = i18n.global.t('notifications.errors.fetch', { resource: i18n.global.t('notifications.resources.controls_apps'), supportEmail: texts.emailSupport }) as string
      showErrorNotification(errorMessage)
      reportError(error)
    } finally {
      state.loading = false
    }
  }

  async function detectTransitioningAppsAndPoll (): Promise<void> {
    for (const app of state.apps ?? []) {
      if (appStatusIsTransient(app.status.code)) {
        transitioningApps.value.push(app.id)
      }
    }

    if (transitioningApps.value.length > 0) {
      await pollTransitioningApps()
    }
  }

  async function pollTransitioningApps (): Promise<void> {
    stopAppStatePolling()

    const transitioningAppsData = await fetchTransitioningApps()
    updateTransitioningAppsStatus(transitioningAppsData)

    startAppStatePolling()
  }

  function stopAppStatePolling () {
    if (transitioningAppsPollingTimeoutId.value) {
      clearTimeout(transitioningAppsPollingTimeoutId.value)
      transitioningAppsPollingTimeoutId.value = null
    }
  }

  async function fetchTransitioningApps (): Promise<ControlsApp[]> {
    const requests: Promise<ControlsApp>[] = transitioningApps.value.map(appId => {
      const payload: GetControlsAppPayload = {
        controlsAppId: appId,
        projectId: appStore.projectId,
      }
      return controlsApiStore.getControlsApp(payload, { storeResult: false })
    })
    return await Promise.all(requests)
  }

  function updateTransitioningAppsStatus (getResults: ControlsApp[]): void {
    for (const result of getResults) {
      const updatedApp = state.apps?.find((item: ControlsAppShort) => item.id === result.id)
      if (updatedApp) {
        updatedApp.status = result.status
        if (!appStatusIsTransient(result.status.code)) {
          transitioningApps.value.splice(transitioningApps.value.indexOf(result.id), 1)
        }
      }
    }
  }

  function startAppStatePolling (forced: boolean = false) {
    if (forced || (transitioningApps.value.length > 0 && !transitioningAppsPollingTimeoutId.value)) {
      transitioningAppsPollingTimeoutId.value = setTimeout(pollTransitioningApps, transitioningAppsPollingTimeout)
    }
  }

  function appStatusIsTransient (status: ControlsAppStatus): boolean {
    return status === ControlsAppStatus.RUN_REQUESTED ||
      status === ControlsAppStatus.RUN_INITIATED ||
      status === ControlsAppStatus.STOP_REQUESTED ||
      status === ControlsAppStatus.STOPPED_MAINTENANCE ||
      status === ControlsAppStatus.STOP_MAINTENANCE_INITIATED ||
      status === ControlsAppStatus.STOP_INITIATED ||
      status === ControlsAppStatus.DELETE_REQUESTED
  }

  async function selectFirstControlsApp (): Promise<void> {
    if (state.apps !== null && state.apps?.length > 0) {
      state.selectedControlsApp = state.apps[0].id
    } else {
      state.selectedControlsApp = null
    }
  }

  function selectControlsApp (id: string): void {
    state.selectedControlsApp = id
  }

  async function toggleControlsApp (controlsAppId: string, currentStatus: number): Promise<void> {
    const shouldRun = currentStatus === ControlsAppStatus.STOPPED
    try {
      const payload: PostControlsAppRunPayload = {
        controlsAppId,
        projectId: appStore.projectId,
        run: shouldRun,
      }
      const result = await controlsApiStore.postControlsAppRun(payload)

      const updatedApp = state.apps?.find((item: ControlsAppShort) => item.id === controlsAppId)
      updatedApp!.status = result.status

      if (appStatusIsTransient(result.status.code)) {
        transitioningApps.value.push(controlsAppId)
        await pollTransitioningApps()
      }
    } catch (error) {
      const action = shouldRun ? i18n.global.t('actions.activated') as string : i18n.global.t('actions.deactivated') as string
      showErrorNotification(`${i18n.global.t('notifications.errors.optimization.toggle', { action, supportEmail: texts.emailSupport })}`)
      reportError(error)
    }
  }

  function setTransitioningApps (appIds: string[]): void {
    transitioningApps.value = appIds
  }

  return {
    clear,
    detectTransitioningAppsAndPoll,
    fetchMoreControlsApps,
    searchControlsApps,
    selectControlsApp,
    selectFirstControlsApp,
    setTransitioningApps,
    startAppStatePolling,
    state,
    stopAppStatePolling,
    toggleControlsApp,
  }
})

if (import.meta.hot) {
  import.meta.hot.accept(acceptHMRUpdate(useAIControlsLoggingAppsStore, import.meta.hot))
}
