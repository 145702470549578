import { ControlsState } from '../types'

/**
 * /src/utils/helpers/controls.ts
 * A helper file for the state of controls.
 * Provides helper methods to retrieve certain information
 * or calculating several controls-states.
 */

/**
 * Mapped numeric controls states to their controls state category.
 */
const CONTROLS_STATE_STATUS_CODES: { [controlsState: string]: number[] } = {
  controls_not_found: [404],
  controls_off: [102],
  controls_on: [202],
}

/**
 * Mapped button colors to their controls state category.
 */
const CONTROLS_STATE_BUTTON_COLORS: { [controlsState: string]: string } = {
  controls_off: 'success',
  controls_off_non_togglable: 'neutral',
  controls_on: 'error',
}

/**
 * Mapped shortcodes to their controls algorithm.
 */
const ALGORITHM_SHORTCODE_MAPPINGS: { [algorithm: string]: string } = {
  custom: 'cust',
  heating_curve: 'curv',
  schedule: 'shed',
}

/**
 * Returns the algorithm short code for the passed algorithm.
 * @param algorithm Algorithm name.
 * @returns Short code of the algorithm if found, otherwise returns the passed algorithm.
 */
export function getAlgorithmShortcode (algorithm: string): string {
  return ALGORITHM_SHORTCODE_MAPPINGS[algorithm] || algorithm
}

/**
 * Returns the controls state the passed code represents.
 * @param code Representational number for an controls state.
 * @returns COntrols state category or null if the passed code is null or a default state if not found the passed code.
 */
export function getControlsStateByCode (code: number|null): ControlsState|null {
  if (code === null) return null
  for (const [controlsState, codes] of Object.entries(CONTROLS_STATE_STATUS_CODES)) {
    if (codes.includes(code)) return controlsState as ControlsState
  }
  return 'controls_off_non_togglable'
}

/**
 * Returns a color string for the controls state, based on the passed controlsState value.
 * Returns default color if no controls state was found for the color.
 * @param controlsState Name of the controls state.
 * @returns Color string of the matching controls state. Returns default color if no match found.
 */
export function getColorOfButtonByControlsStateName (controlsState: string|null): string {
  if (controlsState === null) return 'neutral'
  return CONTROLS_STATE_BUTTON_COLORS[controlsState] || 'neutral'
}

/**
 * Returns the controls state color by it's numerical value.
 * @param controlsStateValue The value that represents a certain controls state. If null passed, returns default color.
 * @returns Color as string value if passed value matches an controls state. Otherwise returns default color.
 */
export function getColorOfButtonByControlsStateCode (controlsStateValue: number|null): string {
  return getColorOfButtonByControlsStateName(
    getControlsStateByCode(controlsStateValue),
  )
}
