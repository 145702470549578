import { useAIControlsLoggingAppsStore } from '@/stores/views/AIControls/Logging/apps'
import { useAIControlsLoggingLogsStore } from '@/stores/views/AIControls/Logging/logs'
import debounce from 'lodash.debounce'
import { computed, onBeforeUnmount } from 'vue'

export function useSelectControlsApp () {
  const controlAppsStore = useAIControlsLoggingAppsStore()
  const logsStore = useAIControlsLoggingLogsStore()

  const applySearchDelayed = debounce(function (value: string) {
    controlAppsStore.searchControlsApps(value)
  }, 500)

  function onControlsAppSelected (id: string) {
    controlAppsStore.selectControlsApp(id)
    logsStore.resetAndFetchLogs()
  }

  onBeforeUnmount(() => {
    // This is needed to reset the store after searching for an app from the ai_controls overview page and selecting it, otherwise the search will still be applied
    // And the results will be still limited to the search query
    controlAppsStore.clear()
  })

  return {
    apps: computed(() => controlAppsStore.state.apps),
    search: computed(() => controlAppsStore.state.search),
    fetchMoreControlsApps: controlAppsStore.fetchMoreControlsApps,
    debouncedSearchApps: applySearchDelayed,
    selectControlsApp: onControlsAppSelected,
  }
}
