import { acceptHMRUpdate, defineStore } from 'pinia'
import { computed, ref } from 'vue'
import { type GetKpiAggregationPayload, useAnalyticsApiStore } from '@aedifion.io/pinia-aedifion-api-stores'
import type { TimeRangeInfo, User } from '@aedifion.io/aedifion-api'
import { formatValue } from '@/filters/formatting'
import { getColorForKPI } from '@/utils/helpers/kpiAggregations'
import i18n from '@/i18n'
import moment from 'moment'
import { perSquareUnitKPIs } from '../Reporting'
import { reportError } from '@/utils/helpers/errors'
import { showErrorNotification } from '@/utils/helpers/notifications'
import texts from '@theme/texts'
import { useAppStore } from '@/stores/app'
import { useUserStore } from '@/stores/user'
import vuexStore from '@/vuex'

export type KPIKeys = typeof perSquareUnitKPIs[number]
type KPIValue = {
  aggregationValue?: string,
  color: string,
  subtitle?: string,
  title: string,
  unit?: string
}

export const useAssetOverviewStore = defineStore('assetOverview', () => {
  const analyticsApiStore = useAnalyticsApiStore()
  const appStore = useAppStore()
  const userStore = useUserStore()

  const loading = ref(false)
  const kpiAggregations = ref<Map<KPIKeys, KPIValue>>(new Map())

  const user = computed<User>(() => userStore.userDetails!)

  async function _fetchKPIAggregation (kpi: KPIKeys) {
    const payload: GetKpiAggregationPayload = {
      currencySystem: user.value?.currency_system,
      kpi,
      projectIds: [appStore.projectId],
      timeAggregation: getKPITimeAggregation(kpi),
      timeWindow: 'P1Y',
      unitsSystem: user.value?.units_system,
    }

    try {
      const sumResponse = await analyticsApiStore.getKpiAggregation(payload, { storeResult: false })
      const projectResult = sumResponse?.project_results?.[0]

      if (shouldStoreResults(Boolean(projectResult?.time_range_info?.filled), kpi)) {
        const unit = replaceMonthlyWithYearly(vuexStore.getters['labels/label']('units', sumResponse.units)?.symbol) ?? undefined

        kpiAggregations.value.set(kpi, {
          aggregationValue: formatValue(projectResult?.aggregation_value, { displayUnit: false, unit }),
          color: getColorForKPI(projectResult?.aggregation_value, kpi),
          subtitle: getDateRangeForKPI(projectResult?.time_range_info),
          title: i18n.global.t(`kpis.${kpi}`),
          unit,
        })
      } else {
        payload.timeAggregation = 'mean'
        const meanResponse = await analyticsApiStore.getKpiAggregation(payload, { storeResult: false })
        const projectResult = meanResponse?.project_results?.[0]

        if (projectResult) {
          const unit = replaceMonthlyWithYearly(vuexStore.getters['labels/label']('units', meanResponse.units)?.symbol) ?? undefined
          const aggregatedValuePerYear = projectResult.aggregation_value! * 12

          kpiAggregations.value.set(kpi, {
            aggregationValue: formatValue(aggregatedValuePerYear, { displayUnit: false, unit }),
            color: getColorForKPI(aggregatedValuePerYear, kpi),
            subtitle: aggregatedValuePerYear ? i18n.global.t('asset_overview.extrapolated_data') : undefined,
            title: i18n.global.t(`kpis.${kpi}`),
            unit,
          })
        }
      }
    } catch (error) {
      reportError(error)
      showErrorNotification(`${i18n.global.t('notifications.errors.fetch', { resource: i18n.global.t('notifications.resources.analysis_results'), supportEmail: texts.emailSupport })}`)
    }
  }

  async function fetchAllKPIAggregations () {
    loading.value = true

    const energyKPIRequests = perSquareUnitKPIs.map((kpi) => _fetchKPIAggregation(kpi))
    await Promise.all(energyKPIRequests)

    loading.value = false
  }

  function clearStore () {
    kpiAggregations.value.clear()
  }

  return {
    clearStore,
    fetchAllKPIAggregations,
    kpiAggregations,
    loading,
  }
})

export function getDateRangeForKPI (timeRange: TimeRangeInfo|undefined) {
  // We subtract one month from the end date, because the analytics API returns the end date as the first day of the next month
  return (timeRange?.start && timeRange?.end) ? `${i18n.global.t('dates.yearly')} - ${moment(timeRange.start).format('MMM YYYY')} - ${moment(timeRange.end).subtract(1, 'month').format('MMM YYYY')}` : ''
}

function getKPITimeAggregation (kpiType: KPIKeys) {
  if (kpiType === 'productivity' || kpiType === 'technical_availability') {
    return 'mean'
  }

  return 'sum'
}

function shouldStoreResults (isFilled: boolean, kpiType: KPIKeys) {
  // We store the results for the productivity and technical availability KPIs, as their time aggregation is 'mean' and not 'sum' in every case
  return isFilled || kpiType === 'productivity' || kpiType === 'technical_availability'
}

export function replaceMonthlyWithYearly (unit: string|undefined) {
  if (!unit) return

  const currentLocale = i18n.global.locale.value

  if (currentLocale === 'en') {
    return unit.replace('mon', 'y')
  } else {
    return unit.replace('mon', 'a')
  }
}

if (import.meta.hot) {
  import.meta.hot.accept(acceptHMRUpdate(useAssetOverviewStore, import.meta.hot))
}
