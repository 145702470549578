<script setup lang="ts">
import { ref } from 'vue'
import ControlsAppSwitch from './ControlsAppSwitch.vue'
import { type DateOrRangeString } from '@/utils/types'
import Datepicker from '@/components/Datepicker.vue'
import { useAIControlsLoggingAppsStore } from '@/stores/views/AIControls/Logging/apps'
import { useAIControlsLoggingLogsStore } from '@/stores/views/AIControls/Logging/logs'
import { useI18n } from 'vue-i18n'
import ControlsAppSelector from '@/views/AIControls/ControlsAppSelector.vue'
import { useSelectControlsApp } from '../Composables/useSelectControlsApp'

const appsStore = useAIControlsLoggingAppsStore()
const logsStore = useAIControlsLoggingLogsStore()

const { t } = useI18n()

const datePickerMenuOpen = ref(false)

function onTimePeriodUpdated (newTimePeriod: DateOrRangeString) {
  logsStore.setTimePeriod(newTimePeriod)
}

// --- execution ---

appsStore.fetchMoreControlsApps().then(() => {
  appsStore.detectTransitioningAppsAndPoll()
  if (appsStore.state.selectedControlsApp && appsStore.state.apps?.some(element => element.id === appsStore.state.selectedControlsApp)) {
    appsStore.selectControlsApp(appsStore.state.selectedControlsApp)
  } else {
    appsStore.selectFirstControlsApp()
  }
  logsStore.resetAndFetchLogs()
})

// #region CONTROL APPS SELECTOR
const { debouncedSearchApps, fetchMoreControlsApps, selectControlsApp, apps, search } = useSelectControlsApp()
// #endregion
</script>

<template>
  <div class="d-flex gap">
    <ControlsAppSelector
      v-model:search="search"
      :selected-controls-app="appsStore.state.selectedControlsApp"
      :control-apps="apps"
      @fetch-more-controls-apps="fetchMoreControlsApps"
      @search-controls-apps="debouncedSearchApps"
      @select-controls-app="selectControlsApp"
    >
      <template #item-action="{ item }">
        <ControlsAppSwitch
          :app-id="item.value"
          :app-status="item.raw.custom?.statusCode"
        />
      </template>
    </ControlsAppSelector>
    <Datepicker
      :value="logsStore.state.timePeriod"
      :text="t('time_period')"
      @update:menu="datePickerMenuOpen = $event"
      @datepicker:change="onTimePeriodUpdated"
    />
  </div>
</template>

<style lang="sass" scoped>
.controls-apps-drop-down
  width: 500px

.time-period-picker
  min-width: fit-content
</style>

<i18n lang="json" locale="de">
{
  "time_period": "Zeitraum"
}
</i18n>
<i18n lang="json" locale="en">
{
  "time_period": "Time period"
}
</i18n>
