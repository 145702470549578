<script setup lang="ts">
import { ColumnHeader, RowData, SortDirection, Width } from '@/components/SummarizedContent/types'
import { computed } from 'vue'
import { EntityLog } from '@aedifion.io/aedifion-api'
import moment from 'moment'
import SummarizedTable from '@/components/SummarizedTable.vue'
import { useAIControlsLoggingLogsStore } from '@/stores/views/AIControls/Logging/logs'
import { useI18n } from 'vue-i18n'

type Keys = 'datetime' | 'message'

const { t } = useI18n()

const logsStore = useAIControlsLoggingLogsStore()

const headers = computed<ColumnHeader<Keys>[]>(() => [{
  key: 'datetime',
  text: t('header.date_and_time'),
  width: Width.MaxContent,
}, {
  key: 'message',
  text: t('header.message'),
}])

const logsRows = computed<RowData<Keys>[]>(() => {
  return (logsStore.state.logs ?? []).map((item: EntityLog) => {
    return {
      datetime: {
        text: moment(item.created_at).format(t('dates.datetime_format')),
      },
      message: {
        text: item.log_message,
      },
    }
  })
})
</script>

<template>
  <div>
    <SummarizedTable
      v-if="logsRows.length > 0"
      :headers="headers"
      :load-more-callback="logsStore.fetchMoreLogs"
      :rows="logsRows"
      :default-sorting="{
        key: 'datetime',
        direction: SortDirection.DESC
      }"
    />
    <div
      v-else-if="logsStore.state.isReloadingLogs"
      data-testid="summarized-table-skeleton"
      class="mt-n4"
    >
      <v-row
        class="d-flex flex-row justify-start content-start mr-2"
      >
        <v-col
          v-for="(index) in 2"
          :key="index"
          cols="2"
        >
          <v-skeleton-loader
            type="text"
            class="mr-auto bg-transparent"
            width="80%"
          />
        </v-col>
      </v-row>
      <v-sheet
        v-for="i in 20"
        :key="i"
      >
        <v-skeleton-loader
          type="table-row"
          class="px-4 summarized-table__skeleton-loader"
        />
      </v-sheet>
    </div>
    <v-progress-circular
      v-if="logsStore.isFetchingMoreLogs"
      :width="3"
      color="primary"
      class="mx-auto mt-2 d-block"
      indeterminate
    />
  </div>
</template>

<style lang="sass" scoped>
.summarized-table__skeleton-loader
  padding-top: 3px
  padding-bottom: 3px

[data-testid="summarized-table-skeleton"] > .v-sheet
  &:nth-child(n+2)
    border-radius: 0 !important
    border-bottom: 0
  &:nth-child(2)
    border-top-right-radius: 4px !important
    border-top-left-radius: 4px !important
  &:last-child
    border-radius: 4px
    border-top-right-radius: 0
    border-top-left-radius: 0
    border-bottom-right-radius: 4px !important
    border-bottom-left-radius: 4px !important
    border-bottom: 1px solid rgb(var(--v-theme-neutral-lighten1)) !important

:deep(.v-skeleton-loader__button)
    width: 180px
</style>

<i18n lang="json" locale="de">
{
  "header": {
    "date_and_time": "Datum und Zeit",
    "message": "Nachricht"
  }
}
</i18n>
<i18n lang="json" locale="en">
{
  "header": {
    "date_and_time": "Date and Time",
    "message": "Message"
  }
}
</i18n>
