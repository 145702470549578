import { ActionTree, Commit } from 'vuex'
import { aedifionApi, DataPointWithContext, DatapointWithScore, ListOfDataPoints, PaginationMeta, PinRecommendation, Success } from '@aedifion.io/aedifion-api'
import { DatapointsState, DatapointWithContextAndScore, SetIsFavoritePayload } from './types'
import { showErrorNotification, showSuccessNotification } from '@/utils/helpers/notifications'
import { DATAPOINT_LIST_ITEMS_PER_PAGE } from '@/settings'
import i18n from '@/i18n'
import { reportError } from '@/utils/helpers/errors'
import { resetStoreState } from './state'
import { RootState } from '../types'
import texts from '@theme/texts'
import { validateNotNullish } from '@/utils/helpers/validate'

// TODO: abort previous GET /project/datapoints requests and only run latest one
// const abortFetchDatpointsController: AbortController = new AbortController()

async function postDatapointTag (commit: Commit, projectId: number, dataPointID: string, tagId: number, notificationResource: string): Promise<void> {
  try {
    const postDatapointTagApiResponse: Success = await aedifionApi.Datapoint.postDatapointTag(dataPointID, projectId, tagId)
    const payload = {
      dataPointID: postDatapointTagApiResponse.resource.datapoint.dataPointID,
      tag: postDatapointTagApiResponse.resource.tag,
    }
    commit('ADD_TAG', payload)
    commit('data_points_view/ADD_TAG_TO_OVERVIEW_ITEM', payload, { root: true })

    showSuccessNotification(i18n.global.t(`notifications.success.${notificationResource}.add`) as string)
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  } catch (error: any) {
    const message = error.status === 400 ? 'duplicated' : 'add'
    showErrorNotification(i18n.global.t(`notifications.errors.${notificationResource}.${message}`, { supportEmail: texts.emailSupport }) as string)
    reportError(error)
  }
}

async function deleteDatapointTag (commit: Commit, projectId: number, dataPointID: string, tagId: number, notificationResource: string): Promise<void> {
  try {
    const deleteDatapointTagApiResponse: Success = await aedifionApi.Datapoint.deleteDatapointTag(tagId, dataPointID, projectId)

    const dataToDelete = {
      dataPointID,
      tagId: deleteDatapointTagApiResponse.resource.tag.id,
    }
    commit('REMOVE_TAG', dataToDelete)
    commit('data_points_view/REMOVE_TAG_FROM_OVERVIEW_ITEM', dataToDelete, { root: true })

    showSuccessNotification(i18n.global.t(`notifications.success.${notificationResource}.remove`) as string)
  } catch (error) {
    showErrorNotification(i18n.global.t(`notifications.errors.${notificationResource}.remove`, { supportEmail: texts.emailSupport }) as string)
    reportError(error)
  }
}

async function updateDatapointTag (commit: Commit, projectId: number, dataPointID: string, tagId: number, key: string, newValue: string, notificationResource: string): Promise<void> {
  try {
    await aedifionApi.Datapoint.deleteDatapointTag(tagId, dataPointID, projectId)
    const projectTagResponse: Success = await aedifionApi.Project.postProjectTag({ key, value: newValue }, projectId)
    const { id } = projectTagResponse.resource
    const datapointTagResponse: Success = await aedifionApi.Datapoint.postDatapointTag(dataPointID, projectId, id)

    const mainResource = {
      dataPointID: datapointTagResponse.resource.datapoint.dataPointID,
      newTag: datapointTagResponse.resource.tag,
      oldTagId: tagId,
    }
    commit('UPDATE_TAG_VALUE', mainResource)
    commit('data_points_view/UPDATE_TAG_VALUE', mainResource, { root: true })

    showSuccessNotification(i18n.global.t(`notifications.success.${notificationResource}.update`) as string)
  } catch (error) {
    showErrorNotification(i18n.global.t(`notifications.errors.${notificationResource}.update`, { supportEmail: texts.emailSupport }) as string)
    reportError(error)
  }
}

export default {
  addDescription: async ({ commit, rootGetters }, { dataPointID, tagID }) => {
    commit('SET_PENDING_TAG_UPDATE', true)
    const projectId = validateNotNullish(
      rootGetters['projects/currentProjectId'] as number|null,
      { errorMessage: i18n.global.t('notifications.errors.no_project_selected') as string },
    )
    await postDatapointTag(commit, projectId, dataPointID, tagID, 'descriptions')

    commit('SET_PENDING_TAG_UPDATE', false)
  },

  addTag: async ({ commit, dispatch, rootGetters }, { dataPointID, tagID }) => {
    commit('SET_PENDING_TAG_UPDATE', true)
    const projectId = validateNotNullish(
      rootGetters['projects/currentProjectId'] as number|null,
      { errorMessage: i18n.global.t('notifications.errors.no_project_selected') as string },
    )
    await postDatapointTag(commit, projectId, dataPointID, tagID, 'tags')
    await dispatch('fetchDatapoint', dataPointID)
    commit('SET_PENDING_TAG_UPDATE', false)
  },

  clear: ({ state }) => {
    resetStoreState(state)
  },

  fetchDatapoint: async ({ commit, rootGetters }, dataPointIdOrHashIdOrNumericId: string): Promise<DataPointWithContext | void> => {
    try {
      const projectId = validateNotNullish(
        rootGetters['projects/currentProjectId'] as number|null,
        { errorMessage: i18n.global.t('notifications.errors.no_project_selected') as string },
      )
      const datapoint: DataPointWithContext = await aedifionApi.Datapoint.getDatapoint(dataPointIdOrHashIdOrNumericId, projectId)
      commit('ADD_DATAPOINT', datapoint)
      return datapoint
    } catch (error) {
      showErrorNotification(`${i18n.global.t('notifications.errors.fetch', { resource: i18n.global.t('notifications.resources.datapoint'), supportEmail: texts.emailSupport })}`)
      reportError(error)
    }
  },

  fetchDatapoints: async ({ commit, getters, rootGetters }, page: number) => {
    commit('SET_LOADING_DATAPOINTS', true)
    try {
      const projectId = validateNotNullish(
        rootGetters['projects/currentProjectId'] as number|null,
        { errorMessage: i18n.global.t('notifications.errors.no_project_selected') as string },
      )
      const totalPages = getters.getTotalPages as number|null
      if ((totalPages !== null && totalPages > 0 && page > totalPages) || page < 1) throw new Error()

      let datapoints: DataPointWithContext[] = []
      let paginationMeta: PaginationMeta

      if (getters.selectedPinForRecommendations) {
        const apiResponse: PinRecommendation = await aedifionApi.Project.getProjectComponentRecommendations(
          projectId!,
          getters.selectedPinForRecommendations.componentInProjectId,
          getters.selectedPinForRecommendations.pinId,
          rootGetters['data_points_view/tagFiltersAsFilterString'] as string|undefined,
          rootGetters['data_points_view/search'] as string|null ?? undefined,
          page,
          DATAPOINT_LIST_ITEMS_PER_PAGE,
        )

        paginationMeta = apiResponse.meta!
        const datapointIdsAndScore: DatapointWithScore[] = apiResponse.items!
        datapoints = await Promise.all(
          datapointIdsAndScore.map(async (datapointIdAndScore: DatapointWithScore) => {
            const datapointWithContext: DataPointWithContext = await aedifionApi.Datapoint.getDatapoint(datapointIdAndScore.dataPointID!, projectId!)
            return {
              ...datapointWithContext,
              score: datapointIdAndScore.score,
            } as DatapointWithContextAndScore
          }),
        )
      } else {
        const apiResponse: ListOfDataPoints = await aedifionApi.Project.getProjectDatapointsByPage(
          projectId!,
          page,
          DATAPOINT_LIST_ITEMS_PER_PAGE,
          rootGetters['data_points_view/tagFiltersAsFilterString'] as string|undefined,
          undefined,
          undefined,
          undefined,
          rootGetters['data_points_view/search'] as string|null ?? undefined,
          undefined,
          rootGetters['data_points_view/isWritableFilterSet'] as boolean,
          rootGetters['data_points_view/isFavoritesFilterSet'] as boolean ? 'only' : undefined,
        )

        paginationMeta = apiResponse.meta!
        const datapointIds: string[] = apiResponse.dataPointIDs!
        datapoints = await Promise.all(datapointIds.map((datapointId: string) => {
          return aedifionApi.Datapoint.getDatapoint(datapointId, projectId!)
        }))
      }

      commit('SET_DATAPOINTS', datapoints)
      commit('SET_PAGINATION', paginationMeta)
    } catch (error) {
      showErrorNotification(`${i18n.global.t('notifications.errors.datapoints.fetch', { supportEmail: texts.emailSupport })}`)
      reportError(error)
    } finally {
      commit('SET_LOADING_DATAPOINTS', false)
    }
  },

  removeDescription: async ({ commit, rootGetters }, { tagId, dataPointID }) => {
    commit('SET_PENDING_TAG_UPDATE', true)
    const projectId = validateNotNullish(
      rootGetters['projects/currentProjectId'] as number|null,
      { errorMessage: i18n.global.t('notifications.errors.no_project_selected') as string },
    )
    await deleteDatapointTag(commit, projectId, dataPointID, tagId, 'descriptions')
    commit('SET_PENDING_TAG_UPDATE', false)
  },

  removeTag: async ({ commit, dispatch, rootGetters }, { tagId, dataPointID }) => {
    try {
      commit('SET_PENDING_TAG_UPDATE', true)
      const projectId = validateNotNullish(
        rootGetters['projects/currentProjectId'] as number|null,
        { errorMessage: i18n.global.t('notifications.errors.no_project_selected') as string },
      )
      await deleteDatapointTag(commit, projectId, dataPointID, tagId, 'tags')
      await dispatch('fetchDatapoint', dataPointID)
      commit('SET_PENDING_TAG_UPDATE', false)
    } catch (error) {
      showErrorNotification(i18n.global.t('notifications.errors.tags.remove', { supportEmail: texts.emailSupport }) as string)
      reportError(error)
    }
  },

  toggleDatapointFavorite: async ({ commit, state, rootGetters }, datapointID: string) => {
    commit('ADD_PENDING_FAVORITES_UPDATE', datapointID)
    try {
      const projectId = validateNotNullish(
        rootGetters['projects/currentProjectId'] as number|null,
        { errorMessage: i18n.global.t('notifications.errors.no_project_selected') as string },
      )
      const datapoint = validateNotNullish(state.datapoints?.find((datapoint: DataPointWithContext) => {
        return datapoint.dataPointID === datapointID
      }))

      if (datapoint.favorite) {
        await aedifionApi.Datapoint.deleteDatapointFavorite(datapointID, projectId)
        commit('SET_IS_FAVORITE', { dataPointID: datapointID, favorite: false } as SetIsFavoritePayload)
      } else {
        await aedifionApi.Datapoint.postDatapointFavorite(datapointID, projectId)
        commit('SET_IS_FAVORITE', { dataPointID: datapointID, favorite: true } as SetIsFavoritePayload)
      }
    } catch (error) {
      showErrorNotification(`${i18n.global.t('notifications.errors.datapoints.favorite', { supportEmail: texts.emailSupport })}`)
      reportError(error)
    } finally {
      commit('REMOVE_PENDING_FAVORITES_UPDATE', datapointID)
    }
  },

  updateDescription: async ({ commit, rootGetters }, { dataPointID, key, newValue, tagId }) => {
    commit('SET_PENDING_TAG_UPDATE', true)
    const projectId = validateNotNullish(
      rootGetters['projects/currentProjectId'] as number|null,
      { errorMessage: i18n.global.t('notifications.errors.no_project_selected') as string },
    )
    await updateDatapointTag(commit, projectId, dataPointID, tagId, key, newValue, 'descriptions')
    commit('SET_PENDING_TAG_UPDATE', false)
  },

  updateTag: async ({ commit, dispatch, rootGetters }, { dataPointID, key, newValue, tagId }) => {
    commit('SET_PENDING_TAG_UPDATE', true)
    const projectId = validateNotNullish(
      rootGetters['projects/currentProjectId'] as number|null,
      { errorMessage: i18n.global.t('notifications.errors.no_project_selected') as string },
    )
    await updateDatapointTag(commit, projectId, dataPointID, tagId, key, newValue, 'tags')
    await dispatch('fetchDatapoint', dataPointID)
    commit('SET_PENDING_TAG_UPDATE', false)
  },
} as ActionTree<DatapointsState, RootState>
