import { acceptHMRUpdate, defineStore } from 'pinia'
import { AnalysisResultShort, KPI, KPIResult, Savings, Success } from '@aedifion.io/aedifion-api'
import { computed, ref } from 'vue'
import { Language, useAnalyticsApiStore } from '@aedifion.io/pinia-aedifion-api-stores'
import { RowData, Severity } from '@/components/SummarizedContent/types'
import { showErrorNotification, showSuccessNotification } from '@/utils/helpers/notifications'
import { getFallbackName } from '@/utils/helpers/locale'
import i18n from '@/i18n'
import { reportError } from '@/utils/helpers/errors'
import { sortByKPI } from '@/stores/helpers/kpiAggregationHelpers'
import texts from '@theme/texts'
import { useAppStore } from '@/stores/app'
import { VUETIFY_COLORS } from '@theme/colors'

export type Keys = 'component' | 'cost' | 'co2' | 'energy'| 'result'

export type KPIS = 'cost' | 'co2' | 'energy'
export interface SavingsMetaData {
  analysisFunctionId: number
  componentId: number
  componentInProjectId: number
}

export interface SavingsPotentialMetaData extends SavingsMetaData {
  analysisFunctionAlphanumericId: string
}

export type RowDataWithMetaData<Keys extends string> = RowData<Keys> & {
  metaData: SavingsMetaData
}

export type SavingsGroup = {
  items: Savings[],
  savings: KPIResult[]
}

export interface PostKPISuccess extends Omit<Success, 'resource'> {
  resource: KPI
}

const COST_KPI_CONTEXT = 'financial_absolute'
const CO2_KPI_CONTEXT = 'co2_emissions_absolute'
const ENERGY_KPI_CONTEXT = 'energy_consumption_absolute'

const CONTEXT_AND_ID_MAP = {
  co2: {
    context: CO2_KPI_CONTEXT,
    identifier: (analysisFunctionAlphanumericId: string) => `${analysisFunctionAlphanumericId}.co2_emissions_absolute`,
  },
  cost: {
    context: COST_KPI_CONTEXT,
    identifier: (analysisFunctionAlphanumericId: string) => `${analysisFunctionAlphanumericId}.financial_absolute`,
  },
  energy: {
    context: ENERGY_KPI_CONTEXT,
    identifier: (analysisFunctionAlphanumericId: string) => `${analysisFunctionAlphanumericId}.energy_consumption_absolute`,
  },
}

function isSavingWithContext (context: string) {
  return (saving: KPIResult) => saving.context === context
}

function mapSeverity (signalColor?: AnalysisResultShort.SignalColorEnum): Severity {
  switch (signalColor) {
    case AnalysisResultShort.SignalColorEnum.Red: return Severity.Error
    case AnalysisResultShort.SignalColorEnum.Yellow: return Severity.Warning
    case AnalysisResultShort.SignalColorEnum.Green: return Severity.Success
    // TODO: add Severity.Unknown ?
    default: return Severity.Success
  }
}

function severityScale (signalColor?: AnalysisResultShort.SignalColorEnum): number {
  switch (signalColor) {
    case AnalysisResultShort.SignalColorEnum.Red: return 3
    case AnalysisResultShort.SignalColorEnum.Yellow: return 2
    case AnalysisResultShort.SignalColorEnum.Green: return 1
    default: return 0
  }
}

function isGroup (saving: Savings|SavingsGroup): saving is SavingsGroup {
  return (saving as SavingsGroup).items !== undefined
}

export const useSavingsPotentialStore = defineStore('savingsPotential', () => {
  const analyticsApiStore = useAnalyticsApiStore()
  const appStore = useAppStore()

  const savingsPotential = ref<Savings[]|null>(null)
  const savingsPotentialProject = ref<number|null>(null)

  const currentSearchFilter = ref<string|null>(null)
  const currentSeverityFilter = ref<Severity[]|null>(null)
  const currentAnalysisFunctionFilter = ref<number|null>(null)
  const currentComponentFilter = ref<number|null>(null)
  const currentHasCostFilter = ref<boolean|null>(null)

  const loading = ref(false)

  const savingsPotentialFiltered = computed<Savings[]>(() => {
    if (!savingsPotential.value) {
      return []
    }

    return savingsPotential.value.filter((savingPotentialEntry: Savings) => {
      if (
        currentSearchFilter.value !== null &&
        !savingPotentialEntry.analysis_result?.interpretation?.toLowerCase()?.includes(currentSearchFilter.value.toLowerCase())
      ) {
        return false
      }

      if (
        currentSeverityFilter.value !== null &&
        !currentSeverityFilter.value.includes(mapSeverity(savingPotentialEntry.analysis_result?.signal_color))
      ) {
        return false
      }

      if (
        currentAnalysisFunctionFilter.value !== null &&
        savingPotentialEntry.analysis_result.function_id !== currentAnalysisFunctionFilter.value
      ) {
        return false
      }

      if (
        currentComponentFilter.value !== null &&
        savingPotentialEntry.component.id !== currentComponentFilter.value
      ) {
        return false
      }

      const costSaving = savingPotentialEntry.savings.find(isSavingWithContext(COST_KPI_CONTEXT))
      if (
        currentHasCostFilter.value !== null &&
        !!(costSaving?.value) !== currentHasCostFilter.value
      ) {
        return false
      }

      return true
    })
  })

  const savingsPotentialGrouped = computed<SavingsGroup[]>(() => {
    const result: SavingsGroup[] = []

    for (const savingPotentialEntry of savingsPotentialFiltered.value) {
      const similarGroup = result.find((savingPotentialGroup) => {
        const firstItem = savingPotentialGroup.items[0]
        const isSameAnalysisFunction = firstItem.analysis_result.function_id === savingPotentialEntry.analysis_result.function_id
        const isSameSeverity = firstItem.analysis_result?.signal_color === savingPotentialEntry.analysis_result?.signal_color
        const isSameAnalysisInterpretation = firstItem.analysis_result?.interpretation === savingPotentialEntry.analysis_result?.interpretation
        const isSameComponent = firstItem.component.alphanumeric_id === savingPotentialEntry.component.alphanumeric_id
        return isSameAnalysisFunction && isSameSeverity && isSameAnalysisInterpretation && isSameComponent
      })

      if (similarGroup) {
        similarGroup.items.push(savingPotentialEntry)
        for (const saving of savingPotentialEntry.savings) {
          const sameSavingFromGroup = similarGroup.savings.find(isSavingWithContext(saving.context ?? ''))
          if (sameSavingFromGroup) {
            if (sameSavingFromGroup.value === undefined && saving.value !== undefined) {
              sameSavingFromGroup.value = saving.value
            } else if (sameSavingFromGroup.value !== undefined) {
              sameSavingFromGroup.value += saving.value ?? 0
            }
          } else {
            similarGroup.savings.push({ ...saving })
          }
        }
      } else {
        result.push({
          items: [savingPotentialEntry],
          savings: savingPotentialEntry.savings.map((saving: KPIResult) => {
            // we clone the saving objects so that we can mutate them later
            // without affecting the original objects
            return { ...saving }
          }),
        })
      }
    }

    return result
  })

  const savingsPotentialSorted = computed<SavingsGroup[]>(() => {
    const result: SavingsGroup[] = []

    // sort children first
    for (const savingsPotentialGroup of savingsPotentialGrouped.value) {
      if (savingsPotentialGroup.items.length <= 1) {
        result.push(savingsPotentialGroup)
      } else {
        // we clone the items array because sort mutates
        const sortedItems = [...savingsPotentialGroup.items].sort(sortByKPI(COST_KPI_CONTEXT))
        result.push({
          ...savingsPotentialGroup,
          items: sortedItems,
        })
      }
    }

    // no need to clone here as result is already a cloned array
    return result.sort((groupA: SavingsGroup, groupB: SavingsGroup) => {
      const firstItemA = groupA.items[0]
      const firstItemB = groupB.items[0]

      if (firstItemA.analysis_result?.signal_color !== firstItemB.analysis_result?.signal_color) {
        const signalScaleA = severityScale(firstItemA.analysis_result?.signal_color)
        const signalScaleB = severityScale(firstItemB.analysis_result?.signal_color)
        return signalScaleB - signalScaleA
      }

      const byCostResult = sortByKPI(COST_KPI_CONTEXT)(groupA, groupB)
      if (byCostResult) {
        return byCostResult
      }

      if (firstItemA.analysis_result && firstItemB.analysis_result) {
        return firstItemA.analysis_result.interpretation!.localeCompare(firstItemB.analysis_result.interpretation!)
      }

      return 0
    })
  })

  function formatSavingToRowData (saving: Savings | SavingsGroup): RowData<Keys> {
    const item = isGroup(saving) ? saving.items[0] : saving

    const result = {
      severity: mapSeverity(item.analysis_result?.signal_color),
      text: item.analysis_result?.interpretation,
    }

    let component
    const componentName = getFallbackName(item.component.nameDE, item.component.nameEN)
    const componentInProjectName = getFallbackName(item.component_in_project.nameDE, item.component_in_project.nameEN)

    if (item.component_in_project.id && componentName) {
      component = {
        link: {
          icon: 'fa:far fa-search',
          target: '_self',
          text: i18n.global.t('savings_potential.filter_by_component'),
          to: {
            name: 'optimization-selected-component-analyses',
            params: {
              componentInProjectId: item.component_in_project.id.toString(),
              project: appStore.projectId.toString(),
            },
            query: {
              search: componentName,
            },
          },
        },
        subtitle: componentName,
        text: componentInProjectName,
      }
    }

    let link
    if (item.component_in_project.id && item.analysis_result?.instance_id && item.analysis_result.id) {
      link = {
        icon: 'fa:far fa-arrow-right',
        text: i18n.global.t('savings_potential.details'),
        to: {
          name: 'optimization-selected-component-analyses',
          params: {
            componentInProjectId: item.component_in_project.id.toString(),
            project: appStore.projectId.toString(),
          },
          query: {
            instance: item.analysis_result.instance_id.toString(),
            result: item.analysis_result.id,
            search: componentInProjectName,
          },
        },
      }
    }

    let co2
    const co2Saving = saving.savings.find(isSavingWithContext(CO2_KPI_CONTEXT))
    if (co2Saving) {
      co2 = {
        text: co2Saving.value,
      }
    }
    let cost
    const costSaving = saving.savings.find(isSavingWithContext(COST_KPI_CONTEXT))
    if (costSaving) {
      cost = {
        text: costSaving.value,
      }
    }
    let energy
    const energySaving = saving.savings.find(isSavingWithContext(ENERGY_KPI_CONTEXT))
    if (energySaving) {
      energy = {
        text: energySaving.value,
      }
    }

    const metaData: SavingsMetaData = {
      analysisFunctionId: item.analysis_result?.function_id,
      componentId: item.component.id!,
      componentInProjectId: item.component_in_project.id!,
    }

    return {
      co2,
      component,
      cost,
      energy,
      link,
      metaData,
      result,
    } as RowData<Keys>
  }

  const savingsPotentialTable = computed<RowData<Keys>[]>(() => {
    return savingsPotentialSorted.value.map((savingsGroup: SavingsGroup) => {
      const group = formatSavingToRowData(savingsGroup)

      group.result!.numberOfComponents = savingsGroup.items.length
      if (savingsGroup.items.length > 1) {
        const firstItem = savingsGroup.items[0]
        group.children = savingsGroup.items.map(formatSavingToRowData)
        group.component = {
          cssClass: 'text-neutral-darken1',
          text: getFallbackName(firstItem.component.nameDE, firstItem.component.nameEN),
        }
        delete group.link
      }

      return group
    })
  })

  const savingsPotentialSummary = computed<RowData<Keys> | null>(() => {
    let co2Value = 0
    let costValue = 0
    let energyValue = 0
    let co2 = null
    let cost = null
    let energy = null

    for (const savingEntry of savingsPotentialFiltered.value) {
      const co2Saving = savingEntry.savings.find(isSavingWithContext(CO2_KPI_CONTEXT))
      if (co2Saving && co2Saving.value) {
        co2Value += co2Saving.value
      }
      const costSaving = savingEntry.savings.find(isSavingWithContext(COST_KPI_CONTEXT))
      if (costSaving && costSaving.value) {
        costValue += costSaving.value
      }
      const energySaving = savingEntry.savings.find(isSavingWithContext(ENERGY_KPI_CONTEXT))
      if (energySaving && energySaving.value) {
        energyValue += energySaving.value
      }
    }

    if (co2Value > 0) {
      co2 = { text: co2Value }
    }
    if (costValue > 0) {
      cost = { text: costValue }
    }
    if (energyValue > 0) {
      energy = { text: energyValue }
    }

    return { co2, cost, energy } as RowData<Keys>
  })

  const severitySummary = computed(() => {
    let red = 0
    let yellow = 0
    let green = 0

    for (const savingEntry of savingsPotentialFiltered.value) {
      if (savingEntry.analysis_result?.signal_color === AnalysisResultShort.SignalColorEnum.Red) {
        red++
      } else if (savingEntry.analysis_result?.signal_color === AnalysisResultShort.SignalColorEnum.Yellow) {
        yellow++
      } else {
        green++
      }
    }

    const result = [{
      color: VUETIFY_COLORS.error.base,
      displayedFigure: String(red),
      figureColor: VUETIFY_COLORS.error.darken2,
      id: 'error',
      proportion: red,
    }, {
      color: VUETIFY_COLORS.warning.base,
      displayedFigure: String(yellow),
      figureColor: VUETIFY_COLORS.warning.darken2,
      id: 'warning',
      proportion: yellow,
    }, {
      color: VUETIFY_COLORS.success.base,
      displayedFigure: String(green),
      figureColor: VUETIFY_COLORS.success.darken2,
      id: 'success',
      proportion: green,
    }]
    return result
  })

  async function fetchSavingsPotential () {
    loading.value = true

    try {
      savingsPotential.value = await analyticsApiStore.getSavingsPotentials({
        /**
         * ? The currency system and units system have to be hardcoded for now, until the backend supports posting different units and currencies
         */
        currencySystem: 'EUR',
        language: i18n.global.locale.value.substring(0, 2) as Language,
        projectId: appStore.projectId,
        unitsSystem: 'metric',
      }, { storeResult: false })

      savingsPotentialProject.value = appStore.projectId
    } catch (error) {
      showErrorNotification(`${i18n.global.t('notifications.errors.fetch', { resource: i18n.global.t('notifications.resources.savings_potential'), supportEmail: texts.emailSupport })}`)
      reportError(error)
    } finally {
      loading.value = false
    }
  }

  const isSavingsPotentialDataStale = computed<boolean>(() => {
    return !loading.value && (
      savingsPotentialProject.value === null ||
      savingsPotentialProject.value !== appStore.projectId
    )
  })

  function resetAllFilters (): void {
    currentSearchFilter.value = null
    currentSeverityFilter.value = null
    currentAnalysisFunctionFilter.value = null
    currentComponentFilter.value = null
    currentHasCostFilter.value = null
  }

  function filterBySearch (search: string): void {
    resetAllFilters()
    currentSearchFilter.value = search
  }

  function filterBySeverities (allowedSeverities: Severity[]): void {
    resetAllFilters()
    currentSeverityFilter.value = allowedSeverities
  }

  function filterByAnalysisFunction (analysisFunctionId: number): void {
    resetAllFilters()
    currentAnalysisFunctionFilter.value = analysisFunctionId
  }

  function filterByComponent (componentId: number): void {
    resetAllFilters()
    currentComponentFilter.value = componentId
  }

  function filterByHasCost (shouldHaveCost: boolean): void {
    resetAllFilters()
    currentHasCostFilter.value = shouldHaveCost
  }

  const isEmptyBecauseOfFiltering = computed<boolean>(() => {
    return savingsPotential.value !== null &&
      savingsPotential.value.length > 0 &&
      savingsPotentialFiltered.value.length === 0
  })

  async function postKPI (kpis: RowData<Keys>, updatedSavings: KPIS[], metaData: SavingsPotentialMetaData): Promise<void> {
    try {
      const savingsToUpdate = updatedSavings.map((kpi) => {
        return analyticsApiStore.postKPI({
          body: {
            analysis_function_id: metaData.analysisFunctionId,
            component_in_project_id: metaData.componentInProjectId,
            context: CONTEXT_AND_ID_MAP[kpi].context,
            identifier: CONTEXT_AND_ID_MAP[kpi].identifier(metaData.analysisFunctionAlphanumericId),
            type: KPI.TypeEnum.Potential,
            units: kpis[kpi]!.unit!,
            value: parseFloat(kpis[kpi]!.text as string || '0'),
          },
          projectId: appStore.projectId,
        })
      })

      const savingsResponses = await Promise.all(savingsToUpdate) as PostKPISuccess[]

      for (const { resource: savingsResponse } of savingsResponses) {
        if (savingsResponse) {
          const kpiIndex = savingsPotential.value?.findIndex((savingsPotential) => savingsPotential.component_in_project.id! === savingsResponse.component_in_project_id && savingsPotential.analysis_result.function_id === savingsResponse.analysis_function_id)
          if (kpiIndex !== -1) {
            const savingsToUpdate = savingsPotential.value![kpiIndex!].savings.find((saving) => saving.context === savingsResponse.context)
            if (savingsToUpdate) {
              savingsToUpdate.value = savingsResponse.value
            } else {
              savingsPotential.value![kpiIndex!].savings.push({
                context: savingsResponse.context,
                identifier: savingsResponse.identifier,
                unit: savingsResponse.units,
                value: savingsResponse.value,
              })
            }
          }
        }
      }
      showSuccessNotification(i18n.global.t('notifications.success.update', { resource: i18n.global.t('notifications.resources.savings_potential') }) as string)
    } catch (error) {
      showErrorNotification(`${i18n.global.t('notifications.errors.update', { resource: i18n.global.t('notifications.resources.savings_potential'), supportEmail: texts.emailSupport })}`)
      reportError(error)
    }
  }

  return {
    currentAnalysisFunctionFilter,
    currentComponentFilter,
    currentHasCostFilter,
    currentSearchFilter,
    currentSeverityFilter,
    fetchSavingsPotential,
    filterByAnalysisFunction,
    filterByComponent,
    filterByHasCost,
    filterBySearch,
    filterBySeverities,
    isEmptyBecauseOfFiltering,
    isSavingsPotentialDataStale,
    loading,
    postKPI,
    resetAllFilters,
    savingsPotential,
    savingsPotentialFiltered,
    savingsPotentialGrouped,
    savingsPotentialProject,
    savingsPotentialSorted,
    savingsPotentialSummary,
    savingsPotentialTable,
    severitySummary,
  }
})

if (import.meta.hot) {
  import.meta.hot.accept(acceptHMRUpdate(useSavingsPotentialStore, import.meta.hot))
}
